@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

*{
    /* overflow-x: hidden !important; */
}


.upper-text{
    transform: translate(8%,-280%);
    /* position: absolute; */
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.toptext-2 {

    /* background: linear-gradient(180deg, #BF9654 0%, #ECD571 50.31%, #BF9654 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 900 !important;
    font-size: 30.8833px; */


    font-family: Zilla Slab;
    font-size: 32.883px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: linear-gradient(180deg, #BF9654 0%, #ECD571 50.31%, #BF9654 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
/* transform: translate(8%,-1250%); */
}

.toptext-1{
    font-family: Zilla Slab !important;
font-size: 32.883px !important;
font-style: normal;
font-weight: 500;
line-height: normal;
color: #ffffff;
/* transform: translate(8%,-500%); */
}



.banner-background {

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 590.44px;
    display: flex;
    justify-content: center;
    align-items: center;

}


/* .coming-soon {
    max-width: 658px;
    width: 100%;
    margin-top: -200px;
}

.coming-soon-text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 75px;
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 35px;
    line-height: 55px;
    letter-spacing: 0.275em;
    color: #FFFFFF;
    background-color: red;
} */

/* .coming-place-name-cover {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
} */

/* .coming-place-name {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 324px;
    width: 100%;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid #000000;
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 17px;
    line-height: 24px;
    letter-spacing: 0.275em;
    color: #2C2828;
} */

/* .place-name {
   
    
    Font-family: Zilla Slab;
    font-weight: 500;
    font-size: 46px;
    color: #FFFFFF;
   
    margin-left: -50%;
   

}

.place-name1 {
   

    Font-family: Zilla Slab;
    font-weight: 500;
    font-size: 46px;
    color: red;
    transform: translate(-50%, -50%);
    margin-left: -39%;

}
.subpara {
   
    
    Font-family: Zilla Slab;
    font-weight: 400;
    font-size: 34px;
    color: #BF9654;

    margin-left: -21%;
    


} */



#input::placeholder {
    color: white;
    font-family: Poppins;
    font-weight: 400;
    font-size: 16px;


}

.css-sghohy-MuiButtonBase-root-MuiButton-root {

    text-transform: lowercase !important;
}


/* .background{
    box-shadow: 0 15px 25px rgba(129, 124, 124, 0.2);
  
    border-radius: 5px;
} */


@media screen and (max-width: 380px) {
    .bookingmodal {
        width: 380px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 364px) {
.bookmodel {
    margin-bottom: -32%!important;
}
}
@media screen and (max-width: 376px) {
    .bookmodel {
        margin-bottom: -30%!important;
    }
    }


.carousel-control-next-icon,
.carousel-control-prev-icon {
    /* display: none; */
color: #ECD571;
}

.carousel-indicators {

    list-style: none;
    display: none;
}

@media screen and (max-width: 1280px) {
    .banner-background {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 590.44px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    /* .cont{
        margin-top: 10% !important;
    } */
}

@media screen and (max-width: 1024px) {
    .banner-background {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 590.44px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    /* .cont{
        margin-top: 10% !important;
    } */
}

@media screen and (max-width: 912px) {
    .banner-background {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 590.44px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .cont{
        margin-top: 0% !important;
    }
}

@media screen and (max-width: 820px) {
    .banner-background {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 325.44px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


@media screen and (max-width: 760px) {

    .banner-background {
        /* height: 500px; */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 590.44px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .coming-soon {
        max-width: 500px;

    }

    .coming-soon-text {
        height: 65px;
        font-size: 35px;
        line-height: 45px;
    }

    .coming-place-name {
        max-width: 280px;
        height: 34px;
        font-size: 18px;
        line-height: 20px;
    }

    .place-name {
        height: 65px;
        font-size: 30px;
        line-height: 40px;
    }

}

@media screen and (max-width: 540px) {
    .banner-background {
        /* height: 216px; */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 500.44px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 576px) {
    .coming-soon {
        max-width: 400px;

    }

    .coming-soon-text {
        height: 55px;
        font-size: 25px;
        line-height: 35px;
    }

    .coming-place-name {
        max-width: 200px;
        height: 24px;
        font-size: 15px;
        line-height: 18px;
    }

    .place-name {
        height: 55px;
        font-size: 25px;
        line-height: 35px;
    }

}

@media screen and (max-width: 440px) {
    .banner-background {

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 260.44px !important;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .upper-text {
        transform: translate(8%,-165%) !important;
    }

    .coming-soon {
        max-width: 250px;
    }

    .coming-soon-text {
        height: 40px;
        font-size: 20px;
        line-height: 30px;
        margin-top: 150px;
    }

    .coming-place-name {
        height: 20px;
        font-size: 10px;
        line-height: 15px;
        margin: 5px;
    }

    .place-name {
        height: 40px;
        font-size: 12px;
        line-height: 22px;
        position: relative;
        top: 80px;
    }

}



@media (max-width: 475px) {
    .banner-background {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 370.44px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
.upper-text{
    transform: translate(5%,-260%) !important;
}

    

}


@media (max-width:376px){

    .upper-text {
        transform: translate(8%,-175%) !important;
    }
      
    .banner-background {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 240.44px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}



@media screen and (max-width: 280px) {
    .banner-background {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 590.44px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}

.cont {
    position: absolute;
    text-align: center;
    height: 100%;
    top: 50%;
    left: 80%;
    transform: translate(-33%, -30%);
    z-index: 1;
    
}


@media (max-width:980px){
    .css-bhp9pd-MuiPaper-root-MuiCard-root {
        margin-top: -19%;
        background-color: #fff;
        color: rgba(0, 0, 0, 0.87);
        -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        padding: 45px;
        border-radius: 4px;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        /* overflow: hidden; */
    }
}

@media (max-width:865px){
    .css-bhp9pd-MuiPaper-root-MuiCard-root {
        margin-top: -20%;
        background-color: #fff;
        color: rgba(0, 0, 0, 0.87);
        -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        padding: 45px;
        border-radius: 4px;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        /* overflow: hidden; */
    }
}



@media (max-width:803px){
    .css-bhp9pd-MuiPaper-root-MuiCard-root {
        margin-top: -23%;
        background-color: #fff;
        color: rgba(0, 0, 0, 0.87);
        -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        padding: 45px;
        border-radius: 4px;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        /* overflow: hidden; */
    }
}

@media (max-width:631px){
    .css-bhp9pd-MuiPaper-root-MuiCard-root {
        margin-top: -28%;
        background-color: #fff;
        color: rgba(0, 0, 0, 0.87);
        -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        padding: 45px;
        border-radius: 4px;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        /* overflow: hidden; */
    }
}

@media (max-width:524px){
    .css-bhp9pd-MuiPaper-root-MuiCard-root {
        margin-top: -26%;
        background-color: #fff;
        color: rgba(0, 0, 0, 0.87);
        -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        padding: 45px;
        border-radius: 4px;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        /* overflow: hidden; */
    }
}

@media (max-width:456px){
    .css-bhp9pd-MuiPaper-root-MuiCard-root {
        margin-top: -17%;
        background-color: #fff;
        color: rgba(0, 0, 0, 0.87);
        -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        padding: 45px;
        border-radius: 4px;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        /* overflow: hidden; */
    }

    .css-ahj2mt-MuiTypography-root{
        white-space:nowrap;
        font-size: 12px !important;
    }
}
@media (max-width:345px){
    .css-bhp9pd-MuiPaper-root-MuiCard-root {
        margin-top: -40%;
        background-color: #fff;
        color: rgba(0, 0, 0, 0.87);
        -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        padding: 45px;
        border-radius: 4px;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        /* overflow: hidden; */
    }
}



.modal {
    position: fixed;

    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 980px) {
    .cont {
        display: none !important;
    }
}


@media (max-width: 980px) {
   
    .banner-background {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 590.44px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


/* 
  @media screen and (max-width: 1150px) {
    .cont{
        display: none;
    }
  } */

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}

@media screen and (min-width: 979px) {
    .modal1 {
        display: none;
    }
}

@media (max-width: 600px) {
    .banner-background {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 540.44px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .toptext {
        margin-bottom: -100px;
    }

    .about-us-text {
        margin-top: -5%;
        font-size: 15px;
    }

    .toptext1 {
        position: sticky;
        color: white;
        font-size: "30px";
        transform: "translate(7%,-400%)";
        font-weight: 400;
        font-family: Zilla Slab;
    }



}

/* .toptext1 {
    position: sticky;
    color: white;
    font-size: "40px";
    transform: "translate(7%,-400%)";
    font-weight: 400;
    font-family: Zilla Slab;
} */

@media screen and (min-width: 300px) {
    .the-magical {
        display: flex;
        justify-content: center;
        align-items: center;
    }

}

/* @media screen and (max-width: 445px) {
    
        .toptext2{
            position:sticky;
            color:white;
            font-size:"40px";
            transform: "translate(7%,-666%)";
            font-weight:400;
            font-family:Zilla Slab;
            margin-top: 20%;
        }
    
 } */
@media screen and (max-width: 461px) {

    .toptext2 {
        position: absolute;
        /* width: 494px; */
        height: 37px;
        left: 0px;
        top: 0px;

        font-family: 'Zilla Slab';
        font-style: normal;
        font-weight: 500;
        font-size: 20.8833px !important;
        line-height: 37px;
        /* identical to box height */


        background: linear-gradient(180deg, #BF9654 0%, #ECD571 50.31%, #BF9654 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

}

@media screen and (max-width: 413px) {

    .toptext1 {
        /* width: 657.63px; */
        height: 80.01px;

        font-family: 'Zilla Slab';
        font-style: normal;
        font-weight: 400;
        font-size: 20.4167px !important;
        line-height: 54px;
        /* or 120% */


        color: #FFFFFF;

        text-shadow: 0px 3.63333px 3.63333px rgba(0, 0, 0, 0.25);

        /* Inside auto layout */

        /* flex: none;
        order: 0;
        flex-grow: 0; */
    }

    .toptext2 {
        position: absolute;
        /* width: 494px; */
        height: 35px;
        left: 0px;
        top: 0px;

        font-family: 'Zilla Slab';
        font-style: normal;
        font-weight: 500;
        font-size: 18.8833px !important;
        line-height: 37px;
        /* identical to box height */


        background: linear-gradient(180deg, #BF9654 0%, #ECD571 50.31%, #BF9654 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

}
@media screen and (max-width: 461px) {

    .toptext2 {
        position: absolute;
        /* width: 494px; */
        height: 37px;
        left: 0px;
        top: 0px;

        font-family: 'Zilla Slab';
        font-style: normal;
        font-weight: 500;
        font-size: 20.8833px !important;
        line-height: 37px;
        /* identical to box height */


        background: linear-gradient(180deg, #BF9654 0%, #ECD571 50.31%, #BF9654 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

}

@media screen and (min-width: 980px) {

    .bookmodel{
        display: none;
        height: 70vh !important;
    }
  

}

@media screen and (min-width: 963px) {

    .bookmodel{
        display: none;
        height: 78vh !important;
    }
  

}


@media (max-width: 1281px) {

    .bookmodel{
        margin-bottom: -15%!important;
    }

}


@media (max-width:376px){
    .bookmodel{
        margin-bottom: -30% !important;
    }
    .upper-text{
        transform: translate(8%,-380%);
        /* position: absolute; */
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
}


.landing-screen{
    display: flex;
    flex-direction: column;
    background-color: white !important;
}

.pro-over{
  height:100%;
  width:80%;
  padding-bottom: 40px;
}
.img-si{
  height: 100%;
  width: 100%;
}




#social
{
  background: url("../../images/bgmlocate.svg");
}
.socialvedio{
  margin-top: 4%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70% !important;

}
.socialmedia{
  position: absolute;
  top: 10%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  font-family: 'Zilla Slab' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 45.4167px;
  text-transform: uppercase;
  line-height: 54px;
  color: #000E38;
  flex: none;
  order: 0;
  flex-grow: 0;
}
/* .vedioone .vediotow .vediothree{  
  height: 50px !important;

} */
.iframevedio{
  width: 70%;
  /* height: 70%; */
}

.connectivity-header-2{
  Font-family:Zilla Slab;
     font-size: 10px;
     color:#0B263B;
     display: flex;
     align-items: center;
     justify-content: center;
}

@media screen and (max-width: 1250px) {
   .vedioone{  
  height: 350px !important;

}
   .vediotow{  
  height: 350px !important;

}
   .vediothree{  
  height: 350px !important;

}
   
    
}
@media screen and (max-width: 850px) {
   .vedioone{  
  height: 300px !important;

}
   .vediotow{  
  height: 300px !important;

}
   .vediothree{  
  height: 300px !important;

}
   
    
}
@media screen and (max-width: 720px) {
  .socialmedia{
    font-size: 35px;
  }
   .vedioone{  
  height: 280px !important;

}
   .vediotow{  
  height: 280px !important;

}
   .vediothree{  
  height: 280px !important;

}
    
.connectivity-header{
  Font-family:Zilla Slab;
     font-size: 35px !important;
     color:#0B263B;
     display: flex;
     align-items: center;
     justify-content: center;
     margin-top: 2%;
}
.connectivity-header-2{
  Font-family:Zilla Slab;
     font-size: 35px !important;
     color:#0B263B;
     margin-left: 10px;
     display: flex;
     align-items: justify;
     width: 98%;
     justify-content: center;
}
}
@media screen and (max-width: 650px) {
   .vedioone{  
  height: 200px !important;
  width:400px !important;

}
   .vediotow{  
    height: 200px !important;
    width:400px !important;
}
   .vediothree{  
    height: 200px !important;
    width:400px !important;
}
.socialmedia{
  font-size: 30px;
}
      
}
@media screen and (max-width: 500px) {
   .vedioone{  
    height: 170px !important;
    width:350px !important;

}
   .vediotow{  
    height: 170px !important;
    width:350px !important;
}
   .vediothree{  
    height: 170px !important;
    width:350px !important;
}
.socialmedia{
  font-size: 28px;
}
}
@media screen and (max-width: 410px) {
   .vedioone{  
    height: 150px !important;
    width:300px !important;

}
   .vediotow{  
    height: 150px !important;
    width:300px !important;
}
   .vediothree{  
    height: 150px !important;
    width:300px !important;
}
.socialmedia{
  font-size: 26px;
}
}
@media screen and (max-width: 370px) {

  .connectivity-header-2{
    margin-left: 4% !important;
  }

   .vedioone{  
    height: 120px !important;
    width:250px !important;

}
   .vediotow{  
    height: 120px !important;
    width:250px !important;
}
   .vediothree{  
    height: 120px !important;
    width:250px !important;
}
      
}
@media screen and (max-width: 748px) {
    .iframe{
        display: none !important;
    }

   
    
}
slick-track {
    /* display: flex; */
  }
  
  .slick-slider {
    display: flex;
    align-items: center;
    /* max-width: 1100px; */
    /* margin: 0 auto; */
  }
  
  .slick-slide {
    /* padding-right: 10px; */
  }
  
  .slick-slide:last-child {
    /* padding-right: 10px; */
  }
  
  .slick-slide img {
    /* display: block; */
  }
  
  .slick-list {
    /* margin: 0 15px; */
  }
  
  .slick-prev {
  
    /* background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../../../../images/navbar/course.svg"); */
  
    width: 20px;
    font-size: larger;
    left: -20px;
    top: 50%;
    position: absolute;
    height: 20px;
    background: rgb(19, 18, 18);
    z-index: 1;
    border-radius: 50%;
    /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); */
  }
  
  .slick-next {
    width: 20px;
    right: -20px;
    top: 50%;
    position: absolute;
    height: 20px;
    background: rgb(17, 16, 16);
    z-index: 1;
    border-radius: 50%;
    /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); */
  }
  
  .slick-prev:focus,
  .slick-prev:hover,
  .slick-next:hover,
  .slick-next:focus {
    color: transparent;
    outline: none;
    background: rgb(0, 0, 0);
  }
  
  .product-actions {
    display: flex;
    justify-content: space-between;
  }
  
  
  .owl-nav>.owl-prev,
  .owl-nav>.owl-next {
    position: absolute;
    top: 40px;
    border-radius: 50% !important;
    background-color:
      #ae1010 !important;
    width: 60px;
    height: 60px !important;
    color: white !important;
    font-size: 60px !important;
  }
  
  .owl-nav>.disabled {
    display: none !important;
  }
  
  .owl-nav>.owl-prev {
    left: 0px;
    margin-left: -60px;
  }
  
  .owl-nav>.owl-next {
    right: 0px;
    margin-right: -60px;
  }
  
  .owl-nav>.owl-prev>span,
  .owl-nav>.owl-next>span {
    position: relative;
    top: -18px;
  }
  
  .heading {
  
  
  
    color: #000000;
  
  
  }
  
  
  
  .slider-wrapper {
    margin: 1rem;
    position: relative;
    overflow: hidden;
  }
  
  .slides-container {
    height: calc(100vh - 2rem);
    width: 100%;
    display: flex;
    overflow: scroll;
    scroll-behavior: smooth;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .slide-arrow {
    position: absolute;
    display: flex;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 4rem;
    background-color: white;
    border: none;
    width: 2rem;
    font-size: 3rem;
    padding: 0;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 100ms;
  }
  
  .slide-arrow:hover,
  .slide-arrow:focus {
    opacity: 1;
  }
  
  #slide-arrow-prev {
    left: 0;
    padding-left: 0.25rem;
    border-radius: 0 2rem 2rem 0;
  }
  
  #slide-arrow-next {
    right: 0;
    padding-left: 0.75rem;
    border-radius: 2rem 0 0 2rem;
  }
  
  .slide {
    width: 100%;
    height: 100%;
    flex: 1 0 100%;
  }


  @media (max-width:600px){
    .desk{
      display:none;
    }
  }
  @media (min-width:600px){
    .mobile{
      display:none;
    }
  }










  @media screen and (max-width: 530px) {
    .socialmedia{
      font-size: 35px;
    }
     .vedioone{  
    height: 280px !important;
  
  }
     .vediotow{  
    height: 280px !important;
  
  }
     .vediothree{  
    height: 280px !important;
  
  }
      
  .connectivity-header{
    Font-family:Zilla Slab;
       font-size: 30px !important;
       color:#0B263B;
       display: flex;
       align-items: center;
       justify-content: center;
  }
  .connectivity-header-2{
    Font-family:Zilla Slab;
       font-size: 28px !important;
       color:#0B263B;
       margin-left: 10px;
       display: flex;
       align-items: justify;
       width: 98%;
       justify-content: center;
  }
  }



  @media screen and (max-width:450px) {
    .socialmedia{
      font-size: 35px;
    }
     .vedioone{  
    height: 280px !important;
  
  }
     .vediotow{  
    height: 280px !important;
  
  }
     .vediothree{  
    height: 280px !important;
  
  }
      
  .connectivity-header{
    Font-family:Zilla Slab;
       font-size: 26px !important;
       color:#0B263B;
       display: flex;
       align-items: center;
       justify-content: center;
  }
  .connectivity-header-2{
    Font-family:Zilla Slab;
       font-size: 24px !important;
       color:#0B263B;
       margin-left: 10px;
       display: flex;
       align-items: justify;
       width: 98%;
       justify-content: center;
  }
  }
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");

.about-us-container {
  height:auto;
  overflow-x: hidden;
  background-color: #0b263b;
 
}


.desktopview-about{
  display: flex !important;
  justify-content: space-between !important;
  margin-right: 3%;
  margin-left: 5%;
  margin-bottom: 3%;
}

@media  (max-width:600px) {
  /* .desktopview-about {
    display: flex !important;
    flex-direction: column !important;
    margin-top: 10% !important;
  } */
}

/* @media  (max-width:600px) {
  .desktopview {
    display: none !important;
  }
} */

/* 
.about-us {
  width: 100%;
  height: 550px;
   background-position: center;
  background-size: cover;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin:10px;
} */

.about-us-text-container {
  margin-left: 18px;
}

/* .about-us-heading {
  height:"20%" ; 
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  display: flex;
 align-items: center;
 justify-content: center;
 color:#BF9654;
} */

  /* background: linear-gradient(
    93.41deg,
    #b88732 6.51%,
    #ecd571 32.05%,
    #b88732 58.28%
  ); */

.abo{
  display: flex;
 align-items: center;
 justify-content: center; 
}

 ul li::before {
  
  color: green;

}

/* .about-us-para{
 
        height: 25px;
        width: 25px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
      } */



.about-us-line {
  width: 137px;
  display: inline-block;
  border-top: 2px solid #ffffff;
  margin-bottom: 7px;
  margin-left: 7px;
}

.about-us-intro {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 43px;
  color: #ffffff;
  margin-top: 34px;
  max-width: 540px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.m{
  font-family: "Lato"; 
}
.magical {
  font-family: Zilla Slab;
}

.about-us-text {
  font-family: 'Zilla Slab';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 40px;
 
  color: #ffffff;
  max-width: 580px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.about-us-image {
  max-width: 809px;

  width: 50%;
  /* margin-right: -100px; */
  /* z-index: 50; */
}
@media screen and (max-width: 1050px) {
  .about-us-heading {
    margin-top: 4% !important;
  }
}


@media screen and (max-width: 1280px) {
  .about-us {
    height: auto;
    padding-top: 7%;
    padding-bottom: 7%;
  }

  .about-us-heading {
    font-size: 13px;
    line-height: 14px;
  }

  .about-us-line {
    width: 100px;
    margin-bottom: 4px;
  }

  .about-us-intro {
    font-size: 13px;
    line-height: 33px;
  }

  .about-us-text {
    font-size: 11px;
    line-height: 35px;
    font-family: 'Zilla Slab';
  }
}

@media screen and (max-width: 850px) {
  .about-us {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    padding-right: 55px;
  }

  .about-us-image {
  
    margin-right: 0px;
    margin-left: auto;
  }
}

@media screen and (max-width: 450px) {
  .about-us {
    padding-right: 30px;
  }

  .about-us-text-container {
    margin-left: 30px;
  }

  .about-us-heading {
    font-size: 10px;
  }

  .about-us-line {
    width: 80px;
    margin-bottom: 4px;
  }

  .about-us-intro {
    font-size: 10px;
    line-height: 30px;
    margin-top: 25px;
  }

  .about-us-text {
    font-size: 8px;
    line-height: 30px;
    margin-top: 10px;
    font-family: 'Zilla Slab';
  }
}


@media screen and (max-width: 600px) {
  .about-us {
    padding-right: 30px;
  }

  .about-us-text-container {
    margin-left: 30px;
  }

  .about-us-heading {
    font-size: 30px;
  }

  .about-us-line {
    width: 80px;
    margin-bottom: 4px;
  }

  .about-us-intro {
    font-size: 10px;
    line-height: 30px;
    margin-top: 25px;
  }

  .about-us-text {
    margin-top: -5%;
    font-size: 15px;
    font-family: 'Zilla Slab';
  }
}


@media (max-width:630px){
  .elite-text{
    font-weight: 300 !important;
    /* margin-top: 20% !important; */
    /* padding-top: 20px; */
    /* padding-bottom: 20px !important; */
  }
}

@media (max-width:445px){

  .css-bhp9pd-MuiPaper-root-MuiCard-root {
    margin-top: -45%;
  }
  .elite-text{
    margin-top: 0% !important;
    font-size: 16px !important;
    /* padding-bottom: 20px !important; */
  }
}
@media (max-width:439px){
  .elite-text{
    margin-top: 0% !important;
    font-size: 16px !important;
    /* padding-bottom: 20px !important; */
    /* margin-bottom: 20px !important; */
  }
}

@media (max-width:400px){
  /* .about-us-image {
    margin-top: 2% !important;
  } */
  .elite-text{
    margin-top: 0% !important;
    font-size: 16px !important;
    /* padding-bottom: 70px !important; */
    /* margin-bottom: 80px !important; */
  }
}

@media (max-width:375px){
  /* .about-us-image {
    margin-top: 17% !important;
  } */
  .elite-text{
    margin-top: 0% !important;
    font-size: 16px !important;
    /* padding-bottom: 70px !important; */
    /* margin-bottom: 20px !important; */
  }
}
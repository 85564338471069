

.aminitieshead{
    margin-top: 4%;


}

.react-multiple-carousel__arrow--right {
    right: calc(12% + 1px) !important;
}

.w h5{
    text-align: center;
    margin-right: 8%;
    color: var(--secondarycolorbf-9654, #BF9654);
text-align: center;
font-family: 'Poppins';
font-size:  19.35px !important;
font-style: normal;
font-weight: 600;
line-height: normal;

}
.w h6{
    color: #000;
font-family: Lato;
font-size: 10.9px !important;
font-style: normal;
font-weight: 500;
line-height: 19.983px;
}


.button {
    /* background-color: #0A253A; */
    border: 1px solid #0B263B !important;
    color: #B79357 !important;
   height: 40px !important;
   width: 160px !important;
   font-size: larger !important;
  
  
   
   
   border-radius: 10px !important;
    
  }
  
  .selectedbtn {
  
    /* display: inline-block;
    outline: 0;
    border: 0;
    cursor: pointer;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    height: 38px;
    /* padding: 8px 24px;
    border-radius: 50px;
    background-image: linear-gradient(180deg,#7c8aff,#3c4fe0);
    box-shadow: 0 4px 11px 0 rgb(37 44 97 / 15%), 0 1px 3px 0 rgb(93 100 148 / 20%);
    transition: all .2s ease-out; */
    background: #001937 !important;
    
  }
  
  .selectedbtn ::after {
   
    display: inline-block;
    outline: 0;
    border: 0;
    cursor: pointer;
    font-weight: 600;
    color: rgb(72, 76, 122);
    font-size: 14px;
    height: 38px;
    /* padding: 8px 24px; */
    border-radius: 50px;
    background-image: linear-gradient(180deg,#fff,#f5f5fa);
    box-shadow: 0 4px 11px 0 rgb(37 44 97 / 15%), 0 1px 3px 0 rgb(93 100 148 / 20%);
  }
.AMENITIES  {
    

font-family: 'Zilla Slab'!important;
font-style: normal;
font-weight: 500;
font-size: 45.4167px !important;
line-height: 54px;

color: #0B263B;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}
@media all and (max-width: 1150px) {
    /* .aminitieshead{
        margin-top: 15%;
    
    
    } */
}
@media all and (max-width: 850px) {
    /* .aminitieshead{
        margin-top: 20%;
    
    
    } */
}
@media all and (max-width: 650px) {
    /* .aminitieshead{
        margin-top: 25%;
    
    
    } */
}
.w h5{
    display: flex;
    align-items: center;
    justify-content: center;
    color:#BF9654;
    font-size: 25px;  
}
.w h6{
    display: flex;
    align-items: center;
    justify-content: center;
    color:black;
    font-size: 16px;  
}
.Luxury{
    display: flex;
    align-items: center;
    justify-content: center; 
}

.react-multiple-carousel__arrow {
    position: absolute;
    outline: 0;
    transition: all .5s;
    border-radius: 35px;
    z-index: 1000;
    border: 0;
    background: rgba(0,0,0,0.5);
    min-width: 43px;
    min-height: 43px;
    opacity: 1;
    cursor: pointer;

}
.react-multi-carousel-dot-list {
    position: absolute;
    bottom: 0;
    display: none;
    left: 0;
    right: 0;
    justify-content: center;
    margin: auto;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    background-color: rgba(191, 150, 84, 0.75);
    -moz-osx-font-smoothing: grayscale;
   
}

.bqyzQp {
    position: relative;
    height: 53vh;
}

.L:hover{
background-color:#0B263B; 
}
    

.CONTACT h6 {
    margin-left: 28%;
    color: #0B263B;
    font-family: Zilla Slab;
    font-size: 45.417px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.contact-main-con{
    background-image: url("../../images/completed/re.png");
}
.container-contact {
    height: 500px;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-direction: row;
    /* background-image: url("../../images/completed/re.png") */
  
}
.loccont{
    /* height: 54px; */

font-family: 'Zilla Slab';
font-style: normal;
font-weight: 500;
font-size: 49.4167px !important;
line-height: 54px;
padding-top: 15px;
/* padding-bottom: 28px; */
color:#ffffff;
/* margin-left: -39%; */


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}



.contact-form {
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 16px;
    margin-left: -8%;

    width: 428px;
    height: 429.87px;

    background: rgba(255, 255, 255, 0.69);
    border-radius: 8px;
}

.contact-form h3 {
    Font-size: 22px;
    color: #0A253A;

}




  .container{
    display: flex;
    align-items: center;
    justify-content: center;
  }
    .b h1 {
       
        color: #0B263B;
        position: relative;
        margin-left: 28%;
        text-align: center;
        font-size: 50px;
        max-width: 42vw;
    }
    

 .box-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
}
.box{
    width: 290px;
    height: 268px;
    background: #E9EFF3;
    box-shadow: 0px 0px 4.5756px 1.1439px rgba(0, 0, 0, 0.2);
    border-radius: 12.915px;
} 
.box ul{
    margin-left: 10px;
    margin-left: 10px;
}
.icons{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
  
}
.icons h6{
    margin-top: 6px;
    color:black;
    font-size: 17px;
}
.box ul{
font-family: 'Lato';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 22px;
color: #000000;
} 
/* .carousel{
    background-color: rgba(191, 150, 84, 0.75);
} */

.iframe{
    /* margin-left: 20%; */
    /* width: 750px; */
    /* height: 600px; */
}



@media all and (max-width: 1900px) {
    .iframe{
        margin-left: 20%;
        width: 750px;
    }
    .contact-form{
        margin-left: -10%;
    }
}



@media all and (max-width: 1600px) {
    .iframe{
        margin-left: 20%;
        width: 750px;
    }
    .contact-form{
        margin-left: -15%;
    }
}
@media all and (max-width: 1400px) {
    .iframe{
        margin-left: 20%;
        width: 650px;
    }
    .contact-form{
        margin-left: -15%;
    }
}


@media all and (max-width: 1200px) {
    .iframe{
        margin-left: 24%;
    }
    .contact-form {
        margin-top: 2%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 8px 16px;
        margin-left: -20%;
    
        width: 408px;
        height: 429.87px;
    
        background: rgba(255, 255, 255, 0.69);
        border-radius: 8px;
    }
}



@media all and (max-width: 1200px) {
    .iframe{
        margin-left: 24%;
    }
    .contact-form {
        margin-top: 2%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 8px 16px;
        margin-left: -20%;
    
        width: 408px;
        height: 429.87px;
    
        background: rgba(255, 255, 255, 0.69);
        border-radius: 8px;
    }
}
@media all and (max-width: 1050px) {
    .iframe{
        margin-left: 20%;
        width: 50%;
    }
    .contact-form {
        margin-top: 2%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 8px 16px;
        /* margin-left: -8%; */
    
        width: 388px !important;
        height: 429.87px;
    
        background: rgba(255, 255, 255, 0.69);
        border-radius: 8px;
    }
    .container-contact{
        gap: 20px !important;
    }
}

@media (max-width:963px) {
    .iframe{
        margin-left: 12%;
        width: 350px !important;
    }
    .contact-form {
        margin-top: 2%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 8px 16px;
        margin-left: -12%;
    
        width: 380px !important;
        height: 429.87px;
    
        background: rgba(255, 255, 255, 0.69);
        border-radius: 8px;
    }
    .container-contact{
    justify-content: space-around !important;
        /* gap: 180px !important; */
    }
}


@media (max-width:846px) {
    .iframe{
        margin-left: 16%;
        width: 350px !important;
    }
    .contact-form {
        margin-top: 2%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 8px 16px;
        /* margin-left: -12%; */
    
        width: 380px !important;
        height: 429.87px;
    
        background: rgba(255, 255, 255, 0.69);
        border-radius: 8px;
    }
   
}


@media (max-width:832px) {
    .iframe{
        margin-left: 18%;
        width: 350px !important;
    }
    .contact-form {
        margin-top: 2%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 8px 16px;
        /* margin-left: -12%; */
    
        width: 380px !important;
        height: 429.87px;
    
        background: rgba(255, 255, 255, 0.69);
        border-radius: 8px;
    }
    .container-contact{
        gap: 10px !important;
    }
   
}
@media all and (max-width: 768px) {

    .b{
        margin-top: 15% !important;
        
    }
    .landmark1{
        margin-left: 14% !important;
    }
    .contact-form{
        align-items:center;
        margin-left: 3%;
    }
    .loccont{
        font-size: 35px !important;
    }
    .AMENITIES{
        font-size: 35px !important;
    }
    .w h5{
        margin-top: 2%;
        font-size: 19px;  
        /* margin-left: 7%; */
    }
    .w h6{
        font-size: 18px;  
        padding: 10px;
        /* margin-left: 7%; */
    }
   
}



@media all and (max-width: 720px) {
    
    .loc5{
        font-size: 35px !important;
    }
    }

@media all and (min-width:600px) {
    
    .tabview{
        display: none;
    }
    .loccont{
        font-size: 35px !important;
    }
    .w h5{
        font-size: 18px;  
        margin-left: 7%;
    }
    
}

@media all and (max-width:1149px) {
    
    .iframe{
        width:500px;
    }
    .container-contact{
        display: flex;
        justify-content: center !important;
        gap: 30px !important;
    }
   }

@media all and (max-width:1151px) {
    
     /* .contact-form{
         display: none;
        } */

        .container-contact{
            gap: 30px !important;
        }


    }
    
    
    .contact-form1 {
        margin-top: -5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 8px 16px;
        gap: 32px;
        
        width: 428px;
        height: 429.87px;
        
        background: rgba(255, 255, 255, 0.69);
        border-radius: 8px;
}

.contact-form1 h3 {
    Font-size: 22px;
    color: #0A253A;
    
}
@media screen and (max-width: 440px) {
    
    .AMENITIES {
        font-size: 28px !important;
    }
    .w h5{
        display: flex;
        align-items: center;
        justify-content: center;
        color:#BF9654;
        font-size: 20px;  
        margin-left: 8%;
    }
    .w h6{
        display: flex;
        align-items: center;
        justify-content: center;
        color:black;
        font-size: 16px;  
        margin-left: 5%;
    }
    .contact-form{
        align-items:center;
        margin-left: 4%;
    }
    
    .gallary25{
        font-size: 28px !important;
    }
}
/* @media screen and (min-width: 749px) {
    .contact-form-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 7.26667px 14.5333px !important;
        gap: 29.07px;
        
        width: 388.77px !important;
        height: 390.59px !important;
        
        background: rgba(255, 255, 255, 0.69)  !important;
        border-radius: 7.26667px;
        
        
      
    }
    
    
} */
@media screen and (max-width: 400px) {
    /* .contact-form{
       display: none;
        
            
    } */
    
.contact-form {
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 16px;
    margin-left: 5%;

    width: 308px !important;
    height: 429.87px;

    background: rgba(255, 255, 255, 0.69);
    border-radius: 8px;
}
  }
.gallary25{
    /* position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%); */
    
    font-family: 'Zilla Slab';
    font-style: normal;
    font-weight: 500;
    font-size: 45.4167px;
    padding-top: 15px;
    background: linear-gradient(272.2deg, #B88732 1.35%, #F3E079 99.45%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    

}
.loc5{
    position: absolute;
    top: 8%;
    left: 50%;
    transform: translate(-50%, -50%);

font-family: 'Zilla Slab' !important;
font-style: normal;
font-weight: 500;
font-size: 45.4167px;
line-height: 54px;

background: linear-gradient(91.62deg, #D6B456 2.66%, #ECD571 51.13%, #BC8D37 97.04%, #BC8D37 97.04%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}
@media screen and (max-width: 700px) {
    .loc5{
       
    font-size: 35.4167px !important;
   
    }

}
@media screen and (max-width: 500px) {
    .loc5{
       
    font-size: 30.4167px !important;
   
    }

}

@media screen and (max-width: 400px) {
    .loc5{
       
    font-size: 25.4167px !important;
   
    }
    
.gallary25{
    font-size: 28px !important;
}


}

@media screen and (max-width: 400px) {
    .gallary25{
        font-size: 28px !important;
    }
.w h5{
    text-align: center;
    margin-right: 8%;
    color: var(--secondarycolorbf-9654, #BF9654);
text-align: center;
font-family: 'Poppins';
font-size:  19.35px !important;
font-style: normal;
font-weight: 600;
line-height: normal;

}
.w h6{
    color: #000;
font-family: Lato;
font-size: 10.9px !important;
font-style: normal;
font-weight: 500;
line-height: 19.983px;
}
}

/* @media (max-width:365px){
    .hee{
        font-size: 20px !important;
    }
} */


@media (max-width:376px){

    /* .book-house{
        height: 40% !important;
    } */


    .gallary25{
        font-size: 25px !important;
    }
    .w h5{
        text-align: center !important;
    }
    .w h6{
        word-spacing: 2px;
        text-align: center !important;
    }
}
.Communities {
    color: #fff;
}
.ongoing-box-container{
    overflow-x: hidden ;
  
  }

.ongoing-box img {
    width: 100%;
    height: 500px;
}

.ongoing-box h2,
h1,
h3,
h4,
h5,
h6,
p {
    color: #fff;

}
@media screen and (max-width: 655px) {

    .line {
        width: 80px !important;
        margin-bottom: 16px !important;

    }

    .completed-image{
        height: 300px !important;
    }
    .ongoing-box 

h3
 {
    font-size: 15px !important;
}

}
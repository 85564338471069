@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
* {
  /* overflow-x: hidden !important; */
}

.coooo{
  padding-left: 4%;
  padding-right: 2%;

}

@media(max-width:600px){
  .desktop{
    display: none !important;
  }
  .mob{
    display: block !important;
  }
  
  

}
@media(min-width:600px){
  .mob{
    display: none !important;
  }
}

@media(max-width:445px){
.mob{
  
  margin-bottom:230px !important;
}
.aboutt{
  font-size: 23px !important;
  padding-bottom:10px;
}
.css-ahj2mt-MuiTypography-roott{
  font-size: 12px !important;
}
}






.about-us-container {
  margin-top: -10%;
}

@media (max-width: 1390px) {
  .about-us-container {
    margin-top: -14%;
  }
}

@media (max-width: 1025x) {
  .about-us-container {
    margin-top: -19% !important;
  }
}

@media (max-width: 505px) {
  /* .css-bhp9pd-MuiPaper-root-MuiCard-root {
      margin-top:30%;
    } */
  .about-us-container {
    margin-top: 39dvh !important;
  }
}

/* project overview part here  */

.first-boxs {
  display: flex;
  justify-content: center;
  align-items: center;
}

.second-boxs {
  display: flex;
  justify-content: center;
  align-items: center;
}
.box1 {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding-right: 20px;
  padding-left: 20px;
  /* border-right: 2px solid #BF9654; */
  /* margin-bottom: 2%; */
}
.box3 {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding-right: 30px;
  padding-left: 20px;
  /* border-right: 2px solid #BF9654; */
  /* margin-bottom: 2%; */
}
.two {
  padding-right: 50px;
}
.box2 {
  border: none;
}

.head-line {
  font-family: "Zilla Slab";
  font-style: normal;
  font-weight: 500;
  font-size: 28.8833px !important;
  color: #bf9654;
}
.sub-head-line {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17.35px !important;
  color: #ffffff;
}
.wid-row {
  height: 1px;
  width: 65%;
  border: 1px solid #bf9654;
  margin-top: 0%;
  margin-bottom: 2%;
}
.wid-col {
  height: 100px;
  width: 1px;
  border: 1px solid #bf9654;
  margin-top: 2%;
  margin-bottom: 3%;
  margin-right: 1%;
}

.wid-col-2 {
  height: 100px;
  width: 1px;
  border: 1px solid #bf9654;
  margin-top: 2%;
  margin-bottom: 3%;
  /* margin-right: 1%; */
}
.wid-t {
  margin-left: 38px;
}

.about-us-heading-two {
  color: #bf9654;
  padding-bottom: 2%;
  font-family: "Zilla Slab";
  font-size: 30px;
  font-weight: 500;
}

.about-us-heading {
  /* background: linear-gradient(93.41deg, #B88732 6.51%, #ECD571 32.05%, #B88732 58.28%);
-webkit-background-clip: text; */
  /* -webkit-text-fill-color: transparent; */
  /* background-clip: text; */
  /* font-size: 50px !important;
font-family: 'Zilla Slab';
font-style: normal;
font-weight: 500; */
  /* line-height: 60px; */

  /* width: 431px;
height: 54px; */

  font-family: "Zilla Slab";
  font-style: normal;
  font-weight: 500;
  font-size: 45.4167px !important;
  margin-top: 2%;
  /* line-height: 54px; */

  /* LINEAR */

  background: linear-gradient(
    93.41deg,
    #b88732 6.51%,
    #ecd571 32.05%,
    #b88732 58.28%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  /* Inside auto layout */
}

.head-line {
  /* position: absolute; */
  font-family: "Zilla Slab";
  font-style: normal;
  font-weight: 500;
  font-size: 30.8833px;
  /* identical to box height */

  color: #bf9654;
}

.about-us {
  /* width: 85%;
  height: 600px;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  align-items: center; */
  /* position: absolute; */
  /* width: 1310.72px !important; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 1%;
  height: 602.29px !important;
  /* left: calc(50% - 1310.72px/2 - 1.36px) !important; */
  /* top: 1287.11px; */

  background: url("../images/aboutusimg.png"), #0b263b !important;
}

/* .about-us{
 
} */

.about-us-text-container {
  /* margin-left: 55px; */
}

/* .about-us-heading {

  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  display: flex;
 align-items: center;
 justify-content: center;
 color:#BF9654;
} */

/* background: linear-gradient(
    93.41deg,
    #b88732 6.51%,
    #ecd571 32.05%,
    #b88732 58.28%
  ); */

.abo {
  display: flex;
  /* align-items: center; */
  /* justify-content: center;  */
}

.about-us-container-ul {
  list-style: none;
  /* position: absolute; */

  /* left: 43%; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17.35px;
  display: flex !important;
  flex-direction: row !important;
  /* line-height: 25px; */
  gap: 40px !important;
  /* top: 11%; */
  /* identical to box height */

  color: #ffffff;
}

ul li::before {
  content: "\2022";
  color: #bf9654;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1.3em;
}

/* .about-us-para{
 
        height: 25px;
        width: 25px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
      } */

.about-us-line {
  width: 137px;
  display: inline-block;
  border-top: 2px solid #ffffff;
  margin-bottom: 7px;
  margin-left: 7px;
}

.about-us-intro {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 43px;
  color: #ffffff;
  margin-top: 34px;
  max-width: 540px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.m {
  font-family: "Lato";
}

.magical {
  font-family: Zilla Slab;
}

.sub-head-line {
  font-family: "Zilla Slab";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 40px;

  color: #ffffff;
  /* max-width: 580px; */
  /* width: 100%; */
  /* margin-top: 5% !important; */
  /* display: flex; */
  flex-wrap: wrap;
  text-align: justify;
}

.about-us-image {
  /* max-width: 809px; */

  /* width: 50%; */
  /* margin-right: -100px; */
  /* z-index: 50; */
  /* height: 60%; */
  /* position: absolute; */
  width: 494.13px;
  height: 330.63px;
  /* left: 60%; */
  /* top: 1438.8px; */
}

@media (max-width: 1500px) {
  .about-us-container {
    margin-top: -12% !important;
  }
  .price {
    margin-left: 58px;
  }
  .p-sub {
    margin-right: -33px;
  }
}

@media (max-width: 1285px) {
  .about-us-container {
    margin-top: -15% !important;
  }
}

@media (max-width: 1225px) {
  .wid-row {
    width: 79%;
  }
}

@media (max-width: 1200px) {
  .about-us-container {
    margin-top: -14% !important;
  }
  .wid-row {
    width: 79%;
  }
}
@media (max-width: 1100px) {
  .about-us-container {
    margin-top: -15% !important;
  }
  .wid-row {
    width: 76%;
  }
}
/* @media (max-width: 880px) {
  .about-us-container {
    margin-top: -28% !important;
  }
} */

@media screen and (max-width: 864px) {
  /* .about-us-container {
    margin-top: -18% !important;
  } */

  .about-us {
    display: flex;
    margin-bottom: 34px;
  }
}
@media screen and (max-width: 1281px) {
  /* .about-us {
    height: auto;
    padding-top: 7%;
    padding-bottom: 7%;
  } */
  .about-us-container {
    margin-top: -8%;
  }

  .about-us-heading {
    font-size: 40px !important;
    line-height: 40px;
  }

  .about-us-line {
    width: 100px;
    margin-bottom: 4px;
  }

  .about-us-intro {
    font-size: 13px;
    line-height: 33px;
  }
}

@media screen and (max-width: 1200px) {
  /* .about-us-image {
    position: absolute;
    width: 454.13px;
    height: 300.63px;
    left: 55%;
  

  } */
  .about-us {
    /* margin-left: 5%; */
  }

  .about-us-text {
    font-family: "Zilla Slab";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 40px;
    /* margin-top: 10% !important; */
    color: #ffffff;

    width: 90%;
    /* display: flex; */
    flex-wrap: wrap;
    text-align: justify;
  }
}

@media (max-width: 1050px) {
  .about-us-container {
    margin-top: -16% !important;
  }
  /* .about-us-image {
    position: absolute;
    width: 414.13px;
    height: 270.63px;
    left: 55%;
    margin-top: 7%;

  } */

  .about-us-text {
    font-family: "Zilla Slab";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 40px;
    /* margin-top: 10% !important; */
    color: #ffffff;

    width: 80%;
    /* display: flex; */
    flex-wrap: wrap;
    text-align: justify;
  }
}

@media (max-width: 969px) {
  .about-us {
    margin-left: 0% !important;
  }

  .about-us-container {
    margin-top: 18% !important;
  }
}

@media (max-width: 912px) {
  .about-us {
    margin-left: 0% !important;
  }

  .about-us-container {
    margin-top: 19% !important;
  }
}

@media (max-width: 768px) {
  .about-us {
    margin-left: 0% !important;
  }

  .about-us-container {
    margin-top: 40% !important;
  }
}

@media (max-width: 675px) {
  .css-bhp9pd-MuiPaper-root-MuiCard-root {
    margin-top: -28%;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    padding: 45px;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    /* overflow: hidden; */
  }
}

@media (max-width: 505px) {
  .css-bhp9pd-MuiPaper-root-MuiCard-root {
    /* margin-top: -46% !important; */
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    padding: 45px;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    /* overflow: hidden; */
  }
}

@media (max-width: 552px) {
  .css-bhp9pd-MuiPaper-root-MuiCard-root {
    margin-top: -34%;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    padding: 45px;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    /* overflow: hidden; */
  }

  .toptext-1 {
    font-family: Zilla Slab !important;
    font-size: 25.883px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #ffffff;
    /* transform: translate(8%,-500%); */
  }

  .toptext-2 {
    font-family: Zilla Slab;
    font-size: 25.883px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: linear-gradient(
      180deg,
      #bf9654 0%,
      #ecd571 50.31%,
      #bf9654 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* transform: translate(8%,-1250%); */
  }
}

@media screen and (max-width: 922px) {
  /* .about-us-image {
    position: absolute;
    width: 414.13px;
    height: 270.63px;
    left: 52%;
    margin-top: 7%;

  } */
  .about-us {
    margin-top: 5%;
    margin-left: 0%;
    display: flex;
    justify-content: space-evenly;
    /* flex-direction: column; */
  }

  .about-us-image {
    width: 45%;
  }
  .about-us-text {
    font-family: "Zilla Slab";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    /* line-height: 40px; */
    /* margin-top: 10% !important; */
    color: #ffffff;

    width: 70%;
    /* display: flex; */
    /* flex-wrap: wrap; */
    text-align: justify;
  }
}

@media (max-width: 905px) {
  .head-line {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 855px) {
  .about-us-image {
    width: 69%;
  }
}

@media (max-width: 445px) {
  .coooo{
    text-align: justify !important;
    /* padding-right: 22px; */
    padding-left: 2%;
    padding-right: 2%;
  }
  .toptext-1 {
    font-family: Zilla Slab !important;
    font-size: 24.883px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #ffffff;
    /* transform: translate(8%,-500%); */
  }
  .toptext-2 {
    font-family: Zilla Slab;
    font-size: 21.883px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: linear-gradient(
      180deg,
      #bf9654 0%,
      #ecd571 50.31%,
      #bf9654 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* transform: translate(8%,-1250%); */
  }
  .about-us-text-container {
    padding-top: 10px !important;
  }
  .about-us-image {
    width: 100%;
    height: 300px;
    margin-left: 3%;
    padding-bottom: 1%;
  }
}
@media (max-width: 442px) {
  .about-us-image {
    width: 100%;
    height: 200px;
    margin-left: 3%;
  }
}
@media (max-width: 425px) {
  .about-us-text-container {
    padding-top: 10px !important;
  }
  .about-us-image {
    width: 70% !important;
    height: 200px !important;
    margin-left: 3%;
    padding-bottom: 1%;
  }
}

.about-us-text {
  font-family: "Zilla Slab";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* line-height: 40px; */
  /* margin-top: 10% !important; */
  color: #ffffff;

  width: 60%;
  /* display: flex; */
  /* flex-wrap: wrap; */
  text-align: justify;
}
.about-us-container {
  /* margin-top: -19dvh !important; */
}

@media screen and (max-width: 820px) {
  .about-us-heading-two {
    font-size: 28px !important;
  }

  /* .about-us-image {
    position: absolute;
    width: 304.13px;
    height: 180.63px;
    left: 60%;
    margin-top: 7%;

  } */

  .about-us-text {
    /* font-family: "Lato";
    font-style: normal; */
    font-weight: 400;
    font-size: 12px !important;
    line-height: 40px !important;
    /* margin-top: 10% !important; */

    width: 70%;
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 750px) {
  .about-us-heading-two {
    font-size: 26px !important;
  }
  .about-us-container {
    /* margin-top: 19dvh !important; */
  }

  /* .about-us-image {
    position: absolute;
    width: 304.13px;
    height: 200.63px;
    left: 52%;
    margin-top: 6%;

  } */

  .about-us-text {
    /* font-family: "Lato";
    font-style: normal; */
    font-weight: 400;
    font-size: 12px !important;
    line-height: 30px !important;
    /* margin-top: 10% !important; */

    width: 50%;
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 630px) {
  .about-us-heading-two {
    font-size: 24px !important;
    /* margin-top: 10% !important; */
    /* margin-bottom: 20px !important; */
  }

  /* .about-us-image {
    position: absolute;
    width: 324.13px;
    height: 200.63px;
    left: 45%;
    margin-top: 7%;

  } */

  .about-us-text {
    /* font-family: "Lato";
    font-style: normal; */
    font-weight: 400;
    font-size: 12px !important;
    line-height: 30px !important;
    /* margin-top: 10% !important; */

    width: 40%;
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 570px) {
  .about-us-heading-two {
    font-size: 20px !important;
    /* margin-top: 5%; */
  }

  /* .about-us-image {
    position: absolute;
    width: 274.13px;
    height: 200.63px;
    left: 45%;
    margin-top: 3%;

  } */

  .about-us-text {
    /* font-family: "Lato";
    font-style: normal; */
    font-weight: 400;
    font-size: 12px !important;
    line-height: 25px !important;
    /* margin-top: 10% !important; */

    width: 40%;
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 500px) {
  .about-us-heading-two {
    font-size: 18px !important;
    /* margin-top: 5%; */
  }

  /* .about-us-image {
    position: absolute;
    width: 174.13px;
    height: 150.63px;
    left: 60%;
    margin-top: 6%;

  } */

  .about-us-text {
    /* font-family: "Lato";
    font-style: normal; */
    font-weight: 400;
    font-size: 12px !important;
    line-height: 20px !important;
    /* margin-top: 12% !important; */

    width: 60%;
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 445px) {
  .about-us-heading-two {
    font-size: 18px !important;
    margin-top: 0%;
  }

  /* .about-us-image {
    position: absolute;
    width: 194.13px;
    height: 180.63px;
    left: 52%;
    margin-top: 7%;

  } */

  .about-us-text {
    /* font-family: "Lato";
    font-style: normal; */
    font-weight: 400;
    font-size: 12px !important;
    line-height: 20px !important;
    /* margin-top: 8% !important; */

    width: 50%;
    display: flex;
    flex-wrap: wrap;
  }
  .about-us {
    height: 702.29px !important;
    padding-bottom: 140px;
  }
  .about-us-image {
    margin-left: 10%;
  }
}

@media (max-width: 460px) {
  .about-us-container {
    margin-top: 33dvh !important;
  }
}

@media screen and (max-width: 400px) {
  .about-us-heading-two {
    font-size: 18px !important;
    /* margin-top: 5%; */
  }

  /* .about-us-image {
    position: absolute;
    width: 184.13px;
    height: 200.63px;
    left: 50%;
    margin-top: 7%;

  } */

  .about-us-text {
    /* font-family: "Lato";
    font-style: normal; */
    font-weight: 400;
    font-size: 12px !important;
    line-height: 20px !important;
    /* margin-top: 12% !important; */

    width: 50%;
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 370px) {
  .about-us-heading-two {
    font-size: 18px !important;
    /* margin-top: -35%; */
  }

  /* .about-us-image {
    position: absolute;
    width: 154.13px;
    height: 140.63px;
    left: 52%;
    margin-top: -25%

  } */

  .about-us-text {
    /* font-family: "Lato";
    font-style: normal; */
    font-weight: 400;
    font-size: 10px !important;
    line-height: 15px !important;
    /* margin-top: -30% !important; */

    width: 50%;
    display: flex;
    flex-wrap: wrap;
  }

  .about-us {
    height: 652.29px !important;
  }

  .css-bhp9pd-MuiPaper-root-MuiCard-root {
    margin-top: -33%;
  }
}

@media screen and (max-width: 850px) {
  /* .about-us {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    padding-right: 55px;
  } */
}

@media screen and (max-width: 450px) {
  /* .about-us {
    padding-right: 30px;
  } */

  .about-us-text-container {
    margin-left: 30px;
  }

  .about-us-heading {
    font-size: 30px !important;
  }

  .about-us-line {
    width: 80px;
    margin-bottom: 4px;
  }

  .about-us-intro {
    font-size: 10px;
    line-height: 30px;
    margin-top: 25px;
  }
}

@media (max-width: 855px) {
  /* .about-us {
    padding-right: 30px;
  } */

  .about-us-text-container {
    /* padding-top: 60px; */
    text-align: center;
  }
  .about-us-text {
    text-align: center;
    width: 100%;
    padding-top: 3%;
    margin-left: 2%;
  }
  .about-us-image {
    padding-bottom: 5%;
    padding-top: 3%;
    margin-left: 6%;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  /* .about-us {
    padding-right: 30px;
  } */

  .coooo{
    text-align: justify !important;
    /* padding-right: 22px; */
    padding-left: 6%;
    padding-right: 2%;
  }
  .about-us-text-container {
    margin-left: 30px;
  }

  .about-us-heading {
    font-size: 30px !important;
  }

  .about-us-line {
    width: 80px;
    margin-bottom: 4px;
  }

  .about-us-intro {
    font-size: 10px;
    line-height: 30px;
    margin-top: 25px;
  }
}

@media screen and (max-width: 400px) {
  .about-us-heading {
    background: linear-gradient(
      93.41deg,
      #b88732 6.51%,
      #ecd571 32.05%,
      #b88732 58.28%
    );
    -webkit-background-clip: text;
    /* -webkit-text-fill-color: transparent; */
    background-clip: text;
    font-size: 27px !important;
    font-family: "Zilla Slab";
    font-style: normal;
    font-weight: 500;
    margin-top: 5%;
    /* line-height: 60px; */
  }

  /* .about-us-container-ul {
    margin-top: 3%;
    margin-left: -6%;
  } */

  /* .about-us {
    margin-bottom: 25%;
  } */
}

@media screen and (max-width: 360px) {
  .about-us-container {
    margin-top: -14dvh !important;
  }
}


@media screen and (max-width: 372px) {
  .about-us-container {
    margin-top: -14dvh !important;
  }
  
  .coooo{
    text-align: justify !important;
    /* padding-right: 22px; */
    padding-left: 0%;
    padding-right: 7%;
  }
}

@media screen and (max-width: 417px) {
  .project-main-container {
    padding-left: 40px;
    padding-right: 70px;
  }
  .wid-row {
    width: 120% !important;
    margin-left: -40px;
  }
  .box1 {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-right: 10px;
    padding-left: 0px;
  }
  .box3 {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-right: 10px;
    padding-left: 0px;
    margin-bottom: 2%;
  }
  .rera {
    padding-left: 0px !important;
  }
  .rp {
    padding-left: 0px !important;
  }

  .mainlogo {
    margin-left: -2.6% !important;
  }
  .con-text {
    /* word-break: break-all !important; */
    /* flex-wrap: wrap !important; */
  }
  .css-bhp9pd-MuiPaper-root-MuiCard-root {
    margin-top: -40%;
  }
  .about-us-container {
    margin-top: 30dvh !important;
  }
}
@media screen and (max-width: 376px) {
  /* .about-us-container {
    margin-top: -20dvh !important;
  } */
  .about-us-container {
    margin-top: 24dvh !important;
  }
  .css-bhp9pd-MuiPaper-root-MuiCard-root {
    margin-top: -32%;
  }
  .contact-form {
    align-items: center;
    margin-left: 2%;
  }
}

.about-us-text-one {
  position: relative;
  left: 326.18px;
  font-family: "Zilla Slab";
  font-style: normal;
  font-weight: 500;
  font-size: 30.8833px !important;
  line-height: 37px;
  /* identical to box height */

  color: #bf9654;
}

.about-us-text-two {
  position: relative;
  /* left: 670.09px; */

  font-family: "Zilla Slab";
  font-style: normal;
  font-weight: 500;
  font-size: 30.8833px !important;
  line-height: 37px;
  /* identical to box height */

  color: #bf9654;
}

.about-us-text-three {
  position: absolute;

  height: 37px;
  left: 1039.21px;
  /* top: 145.38px; */

  font-family: "Zilla Slab" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 30.8833px !important;
  line-height: 37px;
  /* identical to box height */

  color: #bf9654;
}

.about-us-text-one-line {
  position: absolute;
  width: 90.83px;
  height: 0px;
  left: 524.6px;
  margin-top: 50.42px;
  border: 0.108333px solid #bf9654 !important;
  transform: rotate(-90deg);
}

.about-us-text-two-line {
  position: absolute;
  width: 90.83px;
  height: 0px;
  left: 932.95px;
  margin-top: 50.42px;
  border: 0.908333px solid #bf9654;
  transform: rotate(-90deg);
}

.about-us-text-middle-line {
  position: absolute;
  width: 938.79px;
  height: 0px;
  left: 277.15px;
  margin-top: 110.42px;
  border: 0.908333px solid #bf9654 !important;
}

.about-us-text-one-sub {
  position: absolute;
  width: 131px;
  height: 25px;
  left: 370.62px;
  margin-top: 50.42px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15.35px !important;
  line-height: 25px;
  /* identical to box height */

  color: #ffffff;
}

.about-us-text-two-sub {
  position: absolute;
  width: 185px;
  height: 25px;
  left: 702.26px;
  margin-top: 50.42px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16.35px;
  line-height: 25px;
  /* identical to box height */

  color: #ffffff;
}

.about-us-text-three-sub {
  position: absolute;

  height: 25px;
  left: 1010.15px;
  margin-top: 50.42px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16.35px;
  line-height: 25px;
  /* identical to box height */

  color: #ffffff;
}

/* ---------sec */

.about-us-text-one-sec {
  position: absolute;
  width: 181px;
  height: 37px;
  left: 340.18px;
  margin-top: 150.42px;
  /* top: 317.38px; */

  font-family: "Zilla Slab";
  font-style: normal;
  font-weight: 500;
  font-size: 30.8833px !important;
  line-height: 37px;
  /* identical to box height */

  color: #bf9654;
}

.about-us-text-two-sec {
  position: absolute;
  /* width: 219px; */
  height: 37px;
  left: 740.09px;
  margin-top: 150.42px;
  font-family: "Zilla Slab";
  font-style: normal;
  font-weight: 500;
  font-size: 30.8833px !important;
  line-height: 37px;
  /* identical to box height */

  color: #bf9654;
}

.about-us-text-three-sec {
  position: absolute;
  height: 37px;
  left: 1040.09px;
  margin-top: 150.42px;

  font-family: "Zilla Slab";
  font-style: normal;
  font-weight: 500;
  font-size: 30.8833px !important;
  line-height: 37px;
  /* identical to box height */

  color: #bf9654;
}

.about-us-text-one-line-sec {
  position: absolute;
  width: 90.83px;
  height: 0px;
  left: 524.6px;
  margin-top: 200.42px;

  /* SECONDARY COLOR/BF9654 */

  border: 0.108333px solid #bf9654 !important;
  transform: rotate(-90deg);
}

.about-us-text-two-line-sec {
  position: absolute;
  width: 90.83px;
  height: 0px;
  left: 932.95px;
  margin-top: 200.42px;

  /* SECONDARY COLOR/BF9654 */

  border: 0.908333px solid #bf9654;
  transform: rotate(-90deg);
}

.about-us-text-one-sub-sec {
  position: absolute;
  width: 131px;
  height: 25px;
  left: 360.62px;
  margin-top: 200.42px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15.35px !important;
  line-height: 25px;
  /* identical to box height */

  color: #ffffff;
}

.about-us-text-two-sub-sec {
  position: absolute;
  width: 185px;
  height: 25px;
  left: 722.26px;
  margin-top: 200.42px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16.35px;
  line-height: 25px;

  color: #ffffff;
}

.about-us-text-three-sub-sec {
  position: absolute;

  left: 1050.15px;
  margin-top: 200.42px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16.35px;
  line-height: 25px;
  /* identical to box height */

  color: #ffffff;
}

.dwonload-brochure {
  /* Auto layout */
  color: #ffffff;

  /* margin-top: 20% !important; */
  font-size: 12.53px !important;

  width: 256px;
  height: 54px;
  /* left: 42%; */

  border: 2px solid #ffffff !important;
}

.dwonload-brochure:hover {
  color: #b88732 !important;
  border: 2px solid #b88732 !important;
}

@media screen and (max-width: 1350px) {
  .about-us-text-one {
    left: 250.18px;
  }

  .about-us-text-two {
    left: 570.09px;
  }

  .about-us-text-three {
    left: 939.21px;
  }

  .about-us-text-one-line {
    left: 454.6px;
  }

  .about-us-text-two-line {
    left: 832.95px;
  }

  .about-us-text-middle-line {
    left: 195.15px;
  }

  .about-us-text-one-sub {
    left: 295.62px;
  }

  .about-us-text-two-sub {
    left: 602.26px;
  }

  .about-us-text-three-sub {
    left: 910.15px;
  }

  .about-us-text-one-sec {
    left: 250.18px;
  }

  .about-us-text-two-sec {
    left: 630.09px;
  }

  .about-us-text-three-sec {
    left: 939.21px;
  }

  .about-us-text-one-line-sec {
    left: 454.6px;
  }

  .about-us-text-two-line-sec {
    left: 832.95px;
  }

  .about-us-text-one-sub-sec {
    left: 265.15px;
  }

  .about-us-text-two-sub-sec {
    left: 622.26px;
  }

  .about-us-text-three-sub-sec {
    left: 950.15px;
  }
}

@media screen and (max-width: 1165px) {
  .about-us-text-one {
    left: 200.18px;
  }

  .about-us-text-two {
    left: 520.09px;
  }

  .about-us-text-three {
    left: 900.21px;
  }

  .about-us-text-one-line {
    left: 414.6px;
  }

  .about-us-text-two-line {
    left: 792.95px;
  }

  .about-us-text-middle-line {
    left: 145.15px;
  }

  .about-us-text-one-sub {
    left: 245.62px;
  }

  .about-us-text-two-sub {
    left: 555.26px;
  }

  .about-us-text-three-sub {
    left: 870.15px;
  }

  .about-us-text-one-sec {
    left: 200.18px;
  }

  .about-us-text-two-sec {
    left: 570.09px;
  }

  .about-us-text-three-sec {
    left: 890.21px;
  }

  .about-us-text-one-line-sec {
    left: 414.6px;
  }

  .about-us-text-two-line-sec {
    left: 792.95px;
  }

  .about-us-text-one-sub-sec {
    left: 210.15px;
  }

  .about-us-text-two-sub-sec {
    left: 555.26px;
  }

  .about-us-text-three-sub-sec {
    left: 900.15px;
  }
}

@media screen and (max-width: 1110px) {
  .about-us-text-one {
    left: 150.18px;
  }

  .about-us-text-two {
    left: 450.09px;
  }

  .about-us-text-three {
    left: 800.21px;
  }

  .about-us-text-one-line {
    left: 354.6px;
  }

  .about-us-text-two-line {
    left: 702.95px;
  }

  .about-us-text-middle-line {
    left: 100.15px;
  }

  .about-us-text-one-sub {
    left: 200.62px;
  }

  .about-us-text-two-sub {
    left: 490.26px;
  }

  .about-us-text-three-sub {
    left: 780.15px;
  }

  .about-us-text-one-sec {
    left: 160.18px;
  }

  .about-us-text-two-sec {
    left: 500.09px;
  }

  .about-us-text-three-sec {
    left: 800.21px;
  }

  .about-us-text-one-line-sec {
    left: 354.6px;
  }

  .about-us-text-two-line-sec {
    left: 702.95px;
  }

  .about-us-text-one-sub-sec {
    left: 180.15px;
  }

  .about-us-text-two-sub-sec {
    left: 490.26px;
  }

  .about-us-text-three-sub-sec {
    left: 810.15px;
  }
}
@media screen and (max-width: 1060px) {
  .about-us-text-middle-line {
    left: 70.15px;
    width: 900.79px;
  }
}
@media (max-width: 980px) {
  .about-us-text-one {
    left: 100.18px;
    font-size: 22px !important;
  }

  .about-us-text-two {
    left: 380.09px;
    font-size: 22px !important;
  }

  .about-us-text-three {
    left: 750.21px;
    font-size: 22px !important;
  }

  .about-us-text-one-line {
    left: 264.6px;
  }

  .about-us-text-two-line {
    left: 612.95px;
  }

  .about-us-text-middle-line {
    left: 70.15px;
    width: 800.79px;
  }

  .about-us-text-one-sub {
    left: 125.62px;
    font-size: 15px !important;
  }

  .about-us-text-two-sub {
    left: 405.26px;
    font-size: 15px !important;
  }

  .about-us-text-three-sub {
    left: 720.15px;
    font-size: 15px !important;
  }

  .about-us-text-one-sec {
    left: 105.18px;
    font-size: 22px !important;
  }

  .about-us-text-two-sec {
    left: 410.09px;
    font-size: 22px !important;
  }

  .about-us-text-three-sec {
    left: 720.21px;
    font-size: 22px !important;
  }

  .about-us-text-one-line-sec {
    left: 264.6px;
  }

  .about-us-text-two-line-sec {
    left: 612.95px;
  }

  .about-us-text-one-sub-sec {
    left: 115.15px;
    font-size: 15px !important;
  }

  .about-us-text-two-sub-sec {
    left: 400.26px;
    font-size: 15px !important;
  }

  .about-us-text-three-sub-sec {
    left: 730.15px;
    font-size: 15px !important;
  }
  .dwonload-brochure {
    /* Auto layout */
    color: #ffffff;

    /* margin-top: 25% !important; */
    font-size: 12.53px !important;

    width: 256px;
    height: 54px;
    /* left: 36%; */

    border: 2px solid #ffffff !important;
  }
}
@media screen and (max-width: 875px) {
  .about-us-text-one {
    left: 70.18px;
    font-size: 20px !important;
  }

  .about-us-text-two {
    left: 300.09px;
    font-size: 20px !important;
  }

  .about-us-text-three {
    left: 650.21px;
    font-size: 20px !important;
  }

  .about-us-text-one-line {
    left: 204.6px;
  }

  .about-us-text-two-line {
    left: 530.95px;
  }

  .about-us-text-middle-line {
    left: 40.15px;
    width: 745.79px;
  }

  .about-us-text-one-sub {
    left: 85.62px;
    font-size: 15px !important;
  }

  .about-us-text-two-sub {
    left: 315.26px;
    font-size: 15px !important;
  }

  .about-us-text-three-sub {
    left: 640.15px;
    font-size: 15px !important;
  }

  .about-us-text-one-sec {
    left: 75.18px;
    font-size: 20px !important;
  }

  .about-us-text-two-sec {
    left: 340.09px;
    font-size: 20px !important;
  }

  .about-us-text-three-sec {
    left: 650.21px;
    font-size: 20px !important;
  }

  .about-us-text-one-line-sec {
    left: 204.6px;
  }

  .about-us-text-two-line-sec {
    left: 530.95px;
  }

  .about-us-text-one-sub-sec {
    left: 85.15px;
    font-size: 15px !important;
  }

  .about-us-text-two-sub-sec {
    left: 330.26px;
    font-size: 15px !important;
  }

  .about-us-text-three-sub-sec {
    left: 650.15px;
    font-size: 15px !important;
  }
  .dwonload-brochure {
    /* Auto layout */
    color: #ffffff;

    /* margin-top: 25% !important; */
    font-size: 12.53px !important;

    width: 236px;
    height: 44px;
    /* left: 35%; */

    border: 2px solid #ffffff !important;
  }
}
@media screen and (max-width: 790px) {
  .about-us-text-one {
    left: 70.18px;
    font-size: 18px !important;
  }

  .about-us-text-two {
    left: 300.09px;
    font-size: 18px !important;
  }

  .about-us-text-three {
    left: 550.21px;
    font-size: 18px !important;
  }

  .about-us-text-one-line {
    left: 184.6px;
  }

  .about-us-text-two-line {
    left: 460.95px;
  }

  .about-us-text-middle-line {
    left: 40.15px;
    width: 605.79px;
  }

  .about-us-text-one-sub {
    left: 85.62px;
    font-size: 14px !important;
  }

  .about-us-text-two-sub {
    left: 315.26px;
    font-size: 14px !important;
  }

  .about-us-text-three-sub {
    left: 540.15px;
    font-size: 14px !important;
  }

  .about-us-text-one-sec {
    left: 75.18px;
    font-size: 18px !important;
  }

  .about-us-text-two-sec {
    left: 330.09px;
    font-size: 18px !important;
  }

  .about-us-text-three-sec {
    left: 550.21px;
    font-size: 18px !important;
  }

  .about-us-text-one-line-sec {
    left: 184.6px;
  }

  .about-us-text-two-line-sec {
    left: 460.95px;
  }

  .about-us-text-one-sub-sec {
    left: 85.15px;
    font-size: 14px !important;
  }

  .about-us-text-two-sub-sec {
    left: 330.26px;
    font-size: 14px !important;
  }

  .about-us-text-three-sub-sec {
    left: 550.15px;
    font-size: 14px !important;
  }
  .dwonload-brochure {
    /* Auto layout */
    color: #ffffff;

    /* margin-top: 30% !important; */
    font-size: 12.53px !important;

    width: 236px;
    height: 44px;
    /* left: 33%; */

    border: 2px solid #ffffff !important;
  }
}
@media screen and (max-width: 710px) {
  .dwonload-brochure {
    /* Auto layout */
    color: #ffffff;

    /* margin-top: 32% !important; */
    font-size: 12.53px !important;

    width: 206px;
    height: 40px;
    /* left: 40%; */

    border: 2px solid #ffffff !important;
  }
}
@media screen and (max-width: 650px) {
  .about-us-text-one {
    left: 70.18px;
    font-size: 16px !important;
  }

  .about-us-text-two {
    left: 280.09px;
    font-size: 16px !important;
  }

  .about-us-text-three {
    left: 500.21px;
    font-size: 16px !important;
  }

  .about-us-text-one-line {
    left: 184.6px;
  }

  .about-us-text-two-line {
    left: 410.95px;
  }

  .about-us-text-middle-line {
    left: 40.15px;
    width: 570.79px;
  }

  .about-us-text-one-sub {
    left: 80.62px;
    font-size: 10px !important;
  }

  .about-us-text-two-sub {
    left: 295.26px;
    font-size: 10px !important;
  }

  .about-us-text-three-sub {
    left: 495.15px;
    font-size: 10px !important;
  }

  .about-us-text-one-sec {
    left: 75.18px;
    font-size: 16px !important;
  }

  .about-us-text-two-sec {
    left: 300.09px;
    font-size: 16px !important;
  }

  .about-us-text-three-sec {
    left: 500.21px;
    font-size: 16px !important;
  }

  .about-us-text-one-line-sec {
    left: 184.6px;
  }

  .about-us-text-two-line-sec {
    left: 410.95px;
  }

  .about-us-text-one-sub-sec {
    left: 80.15px;
    font-size: 12px !important;
  }

  .about-us-text-two-sub-sec {
    left: 290.26px;
    font-size: 12px !important;
  }

  .about-us-text-three-sub-sec {
    left: 495.15px;
    font-size: 12px !important;
  }
  .dwonload-brochure {
    /* Auto layout */
    color: #ffffff;

    /* margin-top: 34% !important; */
    font-size: 12.53px !important;

    width: 206px;
    height: 40px;
    /* left: 38%; */

    border: 2px solid #ffffff !important;
  }
}
@media screen and (max-width: 610px) {
  .about-us-text-middle-line {
    left: 70.15px;
    width: 490.79px;
  }
  .about-us-text-three-sub-sec {
    left: 485.15px;
    font-size: 12px !important;
  }

  .about-us-text-three-sec {
    left: 490.21px;
    font-size: 16px !important;
  }
}
@media (max-width: 590px) {
  .about-us-text-one {
    left: 50.18px;
    font-size: 14px !important;
  }

  .about-us-text-two {
    left: 240.09px;
    font-size: 14px !important;
  }

  .about-us-text-three {
    left: 450.21px;
    font-size: 14px !important;
  }

  .about-us-text-one-line {
    left: 154.6px;
  }

  .about-us-text-two-line {
    left: 370.95px;
  }

  .about-us-text-middle-line {
    left: 40.15px;
    width: 470.79px;
  }

  .about-us-text-one-sub {
    left: 60.62px;
    font-size: 12px !important;
  }

  .about-us-text-two-sub {
    left: 255.26px;
    font-size: 12px !important;
  }

  .about-us-text-three-sub {
    left: 445.15px;
    flex-wrap: wrap;
    font-size: 12px !important;
  }

  .about-us-text-one-sec {
    left: 55.18px;
    font-size: 14px !important;
  }

  .about-us-text-two-sec {
    left: 260.09px;
    font-size: 14px !important;
  }

  .about-us-text-three-sec {
    left: 450.21px;
    font-size: 14px !important;
  }

  .about-us-text-one-line-sec {
    left: 154.6px;
  }

  .about-us-text-two-line-sec {
    left: 370.95px;
  }

  .about-us-text-one-sub-sec {
    left: 50.15px;
    font-size: 12px !important;
  }

  .about-us-text-two-sub-sec {
    left: 250.26px;
    font-size: 12px !important;
  }

  .about-us-text-three-sub-sec {
    left: 445.15px;
    font-size: 12px !important;
  }
  .dwonload-brochure {
    /* Auto layout */
    color: #ffffff;

    /* margin-top: 40% !important; */
    font-size: 12.53px !important;

    width: 176px;
    height: 35px;
    /* left: 38%; */

    border: 2px solid #ffffff !important;
  }

  .upper-text {
    transform: translate(5%, -280%);
    /* position: absolute; */
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .toptext-1 {
    font-family: Zilla Slab !important;
    font-size: 29.883px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #ffffff;
    /* transform: translate(8%,-500%); */
  }

  .toptext-2 {
    font-family: Zilla Slab;
    font-size: 28.883px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: linear-gradient(
      180deg,
      #bf9654 0%,
      #ecd571 50.31%,
      #bf9654 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* transform: translate(8%,-1250%); */
  }
}
@media screen and (max-width: 520px) {
  .about-us-text-one {
    left: 50.18px;
    font-size: 14px !important;
  }

  .about-us-text-two {
    left: 210.09px;
    font-size: 14px !important;
  }

  .about-us-text-three {
    left: 390.21px;
    font-size: 14px !important;
  }

  .about-us-text-one-line {
    left: 124.6px;
  }

  .about-us-text-two-line {
    left: 310.95px;
  }

  .about-us-text-middle-line {
    left: 40.15px;
    width: 420.79px;
  }

  .about-us-text-one-sub {
    left: 60.62px;
    font-size: 11px !important;
  }

  .about-us-text-two-sub {
    left: 215.26px;
    font-size: 11px !important;
  }

  .about-us-text-three-sub {
    left: 375.15px;
    font-size: 11px !important;
  }

  .about-us-text-one-sec {
    left: 55.18px;
    font-size: 14px !important;
  }

  .about-us-text-two-sec {
    left: 230.09px;
    font-size: 14px !important;
  }

  .about-us-text-three-sec {
    left: 380.21px;
    font-size: 14px !important;
  }

  .about-us-text-one-line-sec {
    left: 124.6px;
  }

  .about-us-text-two-line-sec {
    left: 310.95px;
  }

  .about-us-text-one-sub-sec {
    left: 60.15px;
    font-size: 11px !important;
  }

  .about-us-text-two-sub-sec {
    left: 220.26px;
    font-size: 11px !important;
  }

  .about-us-text-three-sub-sec {
    left: 385.15px;
    font-size: 11px !important;
  }
  .dwonload-brochure {
    /* Auto layout */
    color: #ffffff;

    /* margin-top: 45% !important; */
    font-size: 12.53px !important;

    width: 156px;
    height: 30px;
    /* left: 35%; */

    border: 2px solid #ffffff !important;
  }
}
@media screen and (max-width: 465px) {
  .about-us-text-one {
    left: 30.18px;
    font-size: 14px !important;
  }

  .about-us-text-two {
    left: 170.09px;
    font-size: 14px !important;
  }

  .about-us-text-three {
    left: 330.21px;
    font-size: 14px !important;
  }

  .about-us-text-one-line {
    left: 94.6px;
  }

  .about-us-text-two-line {
    left: 270.95px;
  }

  .about-us-text-middle-line {
    left: 20.15px;
    width: 370.79px;
  }

  .about-us-text-one-sub {
    left: 40.62px;
    font-size: 11px !important;
  }

  .about-us-text-two-sub {
    left: 185.26px;
    font-size: 11px !important;
  }

  .about-us-text-three-sub {
    left: 325.15px;
    font-size: 11px !important;
  }

  .about-us-text-one-sec {
    left: 35.18px;
    font-size: 14px !important;
  }

  .about-us-text-two-sec {
    left: 190.09px;
    font-size: 14px !important;
  }

  .about-us-text-three-sec {
    left: 330.21px;
    font-size: 14px !important;
  }

  .about-us-text-one-line-sec {
    left: 94.6px;
  }

  .about-us-text-two-line-sec {
    left: 270.95px;
  }

  .about-us-text-one-sub-sec {
    left: 40.15px;
    font-size: 11px !important;
  }

  .about-us-text-two-sub-sec {
    left: 190.26px;
    font-size: 11px !important;
  }

  .about-us-text-three-sub-sec {
    left: 335.15px;
    font-size: 11px !important;
  }
  .dwonload-brochure {
    /* Auto layout */
    color: #ffffff;

    /* margin-top: 50% !important; */
    font-size: 12.53px !important;

    width: 146px;
    height: 30px;
    /* left: 35%; */

    border: 2px solid #ffffff !important;
  }
  .css-ahj2mt-MuiTypography-root {
    white-space: wrap;
    font-size: 19px !important;
    /* margin-left: 9px !important; */
  }
}
@media screen and (max-width: 415px) {
  .css-ahj2mt-MuiTypography-root {
    white-space: wrap;
    font-size: 15px !important;
  }
  .css-ahj2mt-MuiTypography-root {
    white-space: wrap;
    font-size: 15px !important;
    /* margin-left: -3px !important; */
  }

  .toptext-1 {
    font-family: Zilla Slab !important;
    font-size: 20.883px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #ffffff;
    /* transform: translate(8%,-500%); */
  }

  .toptext-2 {
    font-family: Zilla Slab;
    font-size: 20.883px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: linear-gradient(
      180deg,
      #bf9654 0%,
      #ecd571 50.31%,
      #bf9654 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* transform: translate(8%,-1250%); */
  }

  .about-us-text-one {
    left: 30.18px;
    font-size: 14px !important;
  }

  .about-us-text-two {
    left: 160.09px;
    font-size: 14px !important;
  }

  .about-us-text-three {
    left: 295.21px;
    font-size: 14px !important;
  }

  .about-us-text-one-line {
    left: 94.6px;
  }

  .about-us-text-two-line {
    left: 240.95px;
  }

  .about-us-text-middle-line {
    left: 20.15px;
    width: 330.79px;
  }

  .about-us-text-one-sub {
    left: 40.62px;
    font-size: 10px !important;
  }

  .about-us-text-two-sub {
    left: 175.26px;
    font-size: 10px !important;
  }

  .about-us-text-three-sub {
    left: 295.15px;
    font-size: 10px !important;
    width: 20%;
    line-height: 18px;
  }

  .about-us-text-one-sec {
    left: 35.18px;
    font-size: 14px !important;
  }

  .about-us-text-two-sec {
    left: 180.09px;
    font-size: 14px !important;
  }

  .about-us-text-three-sec {
    left: 300.21px;
    font-size: 14px !important;
  }

  .about-us-text-one-line-sec {
    left: 94.6px;
  }

  .about-us-text-two-line-sec {
    left: 240.95px;
  }

  .about-us-text-one-sub-sec {
    left: 40.15px;
    font-size: 10px !important;
  }

  .about-us-text-two-sub-sec {
    left: 180.26px;
    font-size: 10px !important;
  }

  .about-us-text-three-sub-sec {
    left: 300.15px;
    font-size: 10px !important;
  }
  .dwonload-brochure {
    /* Auto layout */
    color: #ffffff;

    /* margin-top: 55% !important; */
    font-size: 12.53px !important;

    width: 136px;
    height: 25px;
    /* left: 35%; */

    border: 2px solid #ffffff !important;
  }

  .css-bhp9pd-MuiPaper-root-MuiCard-root {
    margin-top: -38%;
  }

  .slidergallary {
    margin-top: 15px !important;
  }
}
@media screen and (max-width: 376px) {
  .slidergallary {
    margin-top: 8px !important;
  }
  .dwonload-brochure {
    margin-bottom: 10% !important;
  }
}

@media screen and (max-width: 370px) {
  .about-us-text-one {
    left: 30.18px;
    font-size: 14px !important;
  }

  .about-us-text-two {
    left: 150.09px;
    font-size: 14px !important;
  }

  .about-us-text-three {
    left: 280.21px;
    font-size: 14px !important;
  }

  .about-us-text-one-line {
    left: 94.6px;
  }

  .about-us-text-two-line {
    left: 220.95px;
  }

  .about-us-text-middle-line {
    left: 20.15px;
    width: 310.79px;
  }

  .about-us-text-one-sub {
    left: 40.62px;
    font-size: 8px !important;
  }

  .about-us-text-two-sub {
    left: 165.26px;
    font-size: 8px !important;
  }

  .about-us-text-three-sub {
    left: 280.15px;
    font-size: 8px !important;
    width: 20%;
    line-height: 18px;
  }

  .about-us-text-one-sec {
    left: 35.18px;
    font-size: 14px !important;
  }

  .about-us-text-two-sec {
    left: 170.09px;
    font-size: 14px !important;
  }

  .about-us-text-three-sec {
    left: 280.21px;
    font-size: 14px !important;
  }

  .about-us-text-one-line-sec {
    left: 94.6px;
  }

  .about-us-text-two-line-sec {
    left: 220.95px;
  }

  .about-us-text-one-sub-sec {
    left: 40.15px;
    font-size: 8px !important;
  }

  .about-us-text-two-sub-sec {
    left: 160.26px;
    font-size: 8px !important;
  }

  .about-us-text-three-sub-sec {
    left: 280.15px;
    font-size: 8px !important;
  }
  .dwonload-brochure {
    /* Auto layout */
    color: #ffffff;

    /* margin-top: 60% !important; */
    font-size: 12.53px !important;

    width: 126px;
    height: 25px;
    /* left: 38%; */

    border: 2px solid #ffffff !important;
  }
}

@media (max-width: 313px) {
  .dwonload-brochure {
    margin-bottom: 19% !important;
  }
}

@media (max-width: 983px) {
  .wid-row {
    height: 1px;
    width: 70%;
    border: 1px solid #bf9654;
    margin-top: 0%;
    margin-bottom: 2%;
  }
}

@media (max-width: 880px) {
  .project-main-container {
    margin-left: 3%;
  }
  .wid-row {
    height: 1px;
    width: 70%;
    border: 1px solid #bf9654;
    margin-top: 0%;
    margin-bottom: 2%;
  }
  .box1 {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-right: 30px;
    padding-left: 20px;
  }

  .box3 .price {
    /* padding-left: 80px; */
    /* margin-right: 7%; */
    text-align: center;
  }
  .box3 {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-right: 46px;
    padding-left: 20px;
  }

  /* .head-line{
    font-family: 'Zilla Slab';
    font-style: normal;
    font-weight: 500;
    font-size: 28.8833px !important;
    color: #BF9654;
  }
  .sub-head-line{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 17.35px !important;
    color: #ffffff;
  } */
}

@media (max-width: 700px) {
  .project-main-container {
    margin-left: 3%;
  }
  .wid-row {
    height: 1px;
    width: 70%;
    border: 1px solid #bf9654;
    margin-top: 0%;
    margin-bottom: 2%;
  }
  .box1 {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-right: 30px;
    padding-left: 20px;
  }

  .box3 .price {
    /* padding-left: 80px; */
    /* margin-right: 7%; */
    text-align: center;
  }
  .box3 {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-right: 46px;
    padding-left: 20px;
  }

  .head-line {
    font-family: "Zilla Slab";
    font-style: normal;
    font-weight: 500;
    font-size: 20.8833px !important;
    color: #bf9654;
  }
  .sub-head-line {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14.35px !important;
    color: #ffffff;
  }
}

@media (max-width: 776px) {
  .project-main-container {
    margin-left: 5%;
  }
  .wid-row {
    height: 1px;
    width: 80%;
    border: 1px solid #bf9654;
    margin-top: 0%;
    margin-right: 4%;
    margin-bottom: 2%;
  }
  .box1 {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-right: 30px;
    padding-left: 20px;
  }

  .box3 .price {
    /* padding-left: 80px; */
    /* margin-right: 7%; */
    text-align: center;
  }
  .box3 {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-right: 46px;
    padding-left: 20px;
  }

  .head-line {
    font-family: "Zilla Slab";
    font-style: normal;
    font-weight: 500;
    font-size: 20.8833px !important;
    color: #bf9654;
  }
  .sub-head-line {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14.35px !important;
    color: #ffffff;
  }
}

@media (max-width: 656px) {
  .project-main-container {
    margin-left: 5%;
  }
  .wid-row {
    height: 1px;
    width: 80%;
    border: 1px solid #bf9654;
    margin-top: 0%;
    margin-right: 4%;
    margin-bottom: 2%;
  }
  .box1 {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-right: 30px;
    padding-left: 20px;
  }

  .box3 .price {
    /* padding-left: 80px; */
    margin-right: 39%;
    text-align: center;
  }
  .box3 {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-right: 30px;
    padding-left: 20px;
  }
  .wid-col-2 {
    margin-left: 3%;
  }

  .head-line {
    font-family: "Zilla Slab";
    font-style: normal;
    font-weight: 500;
    font-size: 18.8833px !important;
    color: #bf9654;
  }
  .sub-head-line {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    white-space: nowrap;
    font-size: 11.35px !important;
    color: #ffffff;
  }
}

@media (max-width: 536px) {
  .project-main-container {
    margin-left: 5%;
    margin-bottom: 2%;
  }
  .wid-row {
    height: 1px;
    width: 80%;
    border: 1px solid #bf9654;
    margin-top: 0%;
    margin-right: 4%;
    margin-bottom: 2%;
  }
  .box1 {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-right: 30px;
    padding-left: 20px;
  }

  .box3 .price {
    /* padding-left: 80px; */
    margin-right: 39%;
    text-align: center;
  }
  .box3 {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-right: 30px;
    padding-left: 20px;
    margin-bottom: 2%;
  }
  .wid-col-2 {
    margin-left: 3%;
  }

  .head-line {
    font-family: "Zilla Slab";
    font-style: normal;
    font-weight: 500;
    font-size: 14.8833px !important;
    color: #bf9654;
  }
  .sub-head-line {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    white-space: nowrap;
    font-size: 11.35px !important;
    color: #ffffff;
  }
}

@media (max-width: 450px) {
  .project-main-container {
    margin-left: 5%;
    margin-bottom: 2%;
  }
  .wid-row {
    height: 1px;
    width: 80%;
    border: 1px solid #bf9654;
    margin-top: 0%;
    margin-right: 4%;
    margin-bottom: 2%;
  }
  .box1 {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-right: 30px;
    padding-left: 20px;
  }

  .box3 .price {
    /* padding-left: 80px; */
    margin-right: 39%;
    text-align: center;
  }
  .box3 {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-right: 20px;
    padding-left: 10px;
    margin-bottom: 2%;
  }
  .wid-col-2 {
    margin-left: 3%;
  }

  .head-line {
    font-family: "Zilla Slab";
    font-style: normal;
    font-weight: 500;
    font-size: 12.8833px !important;
    color: #bf9654;
  }
  .sub-head-line {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    white-space: nowrap;
    font-size: 9.35px !important;
    color: #ffffff;
  }
}

@media (max-width: 362px) {
  .wid-row {
    width: 100%;
  }
  .project-main-container {
    padding-left: 40px;
    padding-right: 40px;
  }
  .box1 {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-right: 30px;
    padding-left: 10px;
  }
  .box3 {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-right: 10px;
    padding-left: 0px;
    margin-bottom: 2%;
  }
}

@media (max-width: 769px) {
  .bookmodel {
    height: 40vh !important;
  }
}

@media (max-width: 376px) {
  .bookmodel {
    height: 45.5vh !important;
  }
  .about-us {
    /* margin-left: 1% !important; */
  }

  .css-bhp9pd-MuiPaper-root-MuiCard-root {
    margin-top: -46%;
}
}



@media (max-width: 325px) {

  .css-bhp9pd-MuiPaper-root-MuiCard-root {
    margin-top: -46%;
}
.upper-text {
  transform: translate(8%,-138%) !important;
}
}
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

* {
    box-sizing: border-box;
}
.drop-down-button {
    margin-top: 0 !important;
}
.menu-title {
    margin-top: 15px;
}


.banner-search-filter { 
    background-color: white;
    max-width: 900px;
    width: 100%;
    position: relative;
    left: 0;
    right: 0;
     top: -60px; 
     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: 0 auto;
    z-index: 1;
    margin-bottom: -70px;
     margin-top: 73px;    
}

.banner-search {
    max-width: 293px;
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.banner-search-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 25px;
    max-width: 190px;
    width: 100%;
    
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 25px;
    cursor: pointer;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    background: #646060;
}

@media screen and (max-width: 760px) {
    
.banner-search-filter {
  
    padding: 5px 10px;
    max-width: 900px;
 

   
}

.banner-search {
    max-width: 223px;
    height: 80px;
   
}

.banner-search-button {
    
    padding: 5px 15px;
    max-width: 170px;
    font-size: 8px;
    line-height: 10px;
  
}
    
   
  }
@media screen and (max-width: 576px) {
.banner-search-filter {
  
    padding: 5px 20px;
    margin-bottom: -150px;
}

.banner-search-button {
    font-size: 10px;
    
}
  }


  @media screen and (max-width: 440px) {
    .menu-1 {
        height: 30px;
        max-width: 223px;
    }
    .banner-search-filter {
        padding: 5px 20px;
        top: -80px;
       
        width: 335px;
        height: 50px;
        margin-bottom: -60px;
    }
    .banner-search {
        height: 30px;
        width: 85px;
        position: relative;
        right: 10px;
    }
    .banner-search-button {
        font-size: 5px;
        line-height: 10px;
        margin-top: 18px;
    }
    .dropdown-toggle::after {
        margin-left: 5.255em;
    }
    .col-6 {
        flex: 0 0 auto;
        width: 25%;
    }
    .menu-title {
        font-size: 6px;
        margin-top: 12px !important;
    }
    .drop-down-button {
        margin-top: 0px;
        max-width: 30px;
        padding: 0px 0px;
        font-size: 6px;
        line-height: 20px;
        height: 25px;
    }
    .form {
        margin-top: 0px;
        margin-left: -12px;
        min-width: 65px;
    }
    .form-radio {
        font-size: 0.7rem;
    }
    .menu-1 input[type="radio"] {
        width: 10px;
        height: 10px;
        margin-left: 5px;
    }
    .banner-option {
        margin-left: 10px;
        font-size: 4px;
        margin-top: 3px;
        font-weight: 700;
    }
      }

  @media screen and (max-width: 340px) {
    
    .banner-search-filter {
        padding: 2px 10px;
     
       
        width: 240px;
     
    }
    .banner-search {
        height: 30px;
        width: 40px;
       
    }
    .banner-search-button {
        font-size: 3px;
        line-height: 5px;
        padding: 0px;
    }
  
   
 
      }

  



.social-carousel {
    margin-left: 5%;

}















@media screen and (max-width: 1215px) {

    .social-vdo {
        height: 200px !important;
        width: 75% !important;
    }
}

@media screen and (max-width: 1055px) {
    .social-carousel {
        margin-left: 14%;

    }

    .react-multiple-carousel__arrow--right {
        right: calc(16% + 1px) !important;
    }

}

@media screen and (max-width: 960px) {
    .social-carousel {
        margin-left: 9%;

    }

    .react-multiple-carousel__arrow--right {
        right: calc(14% + 1px) !important;
    }

}

@media screen and (max-width: 870px) {
    .social-carousel {
        margin-left: 5%;

    }

    .react-multiple-carousel__arrow--right {
        right: calc(10% + 1px) !important;
    }

}

@media screen and (max-width: 830px) {
    .social-carousel {
        margin-left: 2%;

    }

    .react-multiple-carousel__arrow--right {
        right: calc(7% + 1px) !important;
    }

}



@media screen and (max-width: 811px) {
    .social-carousel {
        margin-left: 2%;

    }
    .vedioone{
        gap: 10px;
    }
    .social-vdo {
        height: 200px !important;
        
        width: 75% !important;
    }

    .react-multiple-carousel__arrow--right {
        right: calc(7% + 1px) !important;
    }

}
@media screen and (max-width: 801px) {
    .social-carousel {
        margin-left: 1%;

    }
  
}

@media screen and (max-width: 750px) {
    .social-vdo {
        height: 230px !important;
        width: 70% !important;
    }
    .social-carousel {
        margin-left: 0%;

    }

    .react-multiple-carousel__arrow--right {
        right: calc(7% + 1px) !important;
    }

}
@media screen and (max-width: 650px) {
    .social-vdo {
        height: 300px !important;
        width: 80% !important;
    }
    .social-carousel {
        margin-left: 21%;

    }

    .react-multiple-carousel__arrow--right {
        right: calc(7% + 1px) !important;
    }

}
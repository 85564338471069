.Communities{
    color: #fff;
 
    
}


.upcoming-heading{
    display: flex;
    justify-content: center;
    align-items: center;
}
.upcoming-heading-container
{
    display: flex;
    justify-content: space-between;
}
.ongoing-box img {
    width: 100%;
    height: 500px;
}

.ongoing-box h2,h1, h3, h4,h5,h6,p{
    color: #fff;
    
}

.dropdown-toggle , .dropdown-menu{
    padding-top:12px;
    padding-bottom:12px;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 15px;
    background-color: white;
    color: black;
    border: none;
    border-radius: 0;
}
.dropdown-menu{
    padding-right: 6px !important;
    padding-left: 6px !important;
}
@media screen and (max-width: 991px){
    .drop_buttons{
        margin-right: 0px !important;
    }
}
@media screen and (max-width: 720px){
   
    
    .white-text{
        font-size: 12px!important;
    }
    .line{
        width: 100px !important;
    }
    .linee{
        margin-bottom: 5px!important; 
    }
}
@media screen and (max-width: 440px){
    .dropdown-toggle::after {
    margin-left: 2px !important;
    }
}
@media screen and (max-width: 655px){
   
    .dropdown-toggle{
        font-size: 10px;
        padding:5px !important;
    }
    .dropdown-menu{
        font-size: 10px;
        padding: 0px !important;
    }
  
    .white-text{
        font-size: 15px!important;
    }
    .line{
        width: 80px !important;
        
    }
    .ongoing-box h4{
        font-size: 15px !important;
        
    }
    
}
@media screen and (max-width: 500px){
    .drop_buttons{
        margin-top: 10px !important;
    }
}
@media screen and (max-width: 450px){
   
    .dropdown-toggle{
        font-size: 8px;
      
    }
    .dropdown-menu{
        font-size: 8px;
       
    }
    .line{
        width: 60px !important;
        
    }
  
    .white-text{
        font-size: 12px!important;
    }
    .upcominghr{
        width: 100px !important;
    }
   
    
    
}


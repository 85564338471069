@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');


/* * {
    box-sizing: border-box;
} */

/* .latest-launches-container {
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 400px;
    background: #EFF7FF;
    border: 1px solid #000000;
    padding-left: 60px;
    padding-right: 15px;
    min-height: 600px;
    height: auto;
} */

/* .latest-launches-heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.09em;
    color: #2C2828;
    display: inline-block;
} */



/* .element.style {
    display: none !important;
} */
.connectivity-header-2{



    margin-left: 74.48px !important;

    
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 21.8px !important;

    
    color: #000000
}

#Gallery{
    background: url('../../../images/buildingbg.png');
}
.slidergallary{
    margin-top: 30px;
    /* position: absolute; */
    /* top:180px;
    left: 30%; */
    width: 60%;
    margin-left: 20%;
    padding-bottom: 40px;
   

}





@media (max-width: 600px)
{
.slidergallary {
    /* margin-top: 205px; */
    position: absolute;
    top: 55px !important;
    width: 80%;
    left: 10%;
}
}

@media (max-width: 500px)
{
.slidergallary {
    /* margin-top: 205px; */
    position: absolute;
    top: 30px !important;
    width: 80%;
    left: 10%;
}
}


@media (max-width:467px)
{
.slidergallary {
    /* margin-top: 205px; */
    position: absolute;
    top: 20px !important;
    width: 80%;
    left: 10%;
}
}
















.gal{
    position: absolute;
}
.the{
    background-image: url(../../../images/bgmlocate.svg);
 
}
.the h1{
    Font-family:Zilla Slab;
 font-size: 40px;
 color:#0B263B;
 display: flex;
 align-items: center;
 justify-content: center;

}

.connectivity-header{


font-family: 'Zilla Slab' !important;
font-style: normal !important;
font-weight: 500 !important;
font-size: 45.4167px !important;
margin-top: 1.8%;

color: #0B263B;
}
.connimg{
width: 1303.57px;

    
}

@media screen and (max-width: 1310px) {
    .connimg{
        width: 1203.57px;
        
            
        }
  }
@media screen and (max-width: 1210px) {
    .connimg{
        width: 1103.57px;
        
            
        }
  }
@media screen and (max-width: 1110px) {
    .connimg{
        width: 1003.57px;
        
            
        }
  }
@media screen and (max-width: 1010px) {
    .connimg{
        width: 903.57px;
        
            
        }
  }
@media screen and (max-width: 910px) {
    .connimg{
        width: 803.57px;
        
            
        }
  }
@media screen and (max-width: 810px) {
    .connectivity-header-2{
        font-size: 16px !important;
    }
    .connimg{
        width: 703.57px;
        
            
        }
  }

  @media screen and (max-width: 800px) {

    /* .the h1{
      font-size: 35px !important;
    } */
    .thee{
      width: 98%;padding-right: 27px;
      margin-left: 2%;
      text-align: justify !important;
    }
  }
  @media screen and (max-width: 720px) {

  .the h1{
    font-size: 35px !important;
  }
  .thee{
    width: 98%;padding-right: 27px;
    margin-left: 3.3%;
    text-align: justify !important;
  }
}
@media screen and (max-width: 710px) {
    .connimg{
        width: 603.57px;
        
            
        }
  }
@media screen and (max-width: 610px) {
    .connimg{
       display: none;
        
            
        }
        .connectivity-header-2{
            font-size: 14px !important;
            margin-left: -2% !important;
        }
  }
@media screen and (min-width: 610px) {
    .connmobile{
       display: none;
    }
        .connectivity-header-2{
            font-size: 14px !important;
            margin-left: -2% !important;
        }
  }






.latest-launches-line {
    width: 180px;
    margin-bottom: 9px;
    margin-left: 9px;
    display: inline-block;
    border-top: 1px solid #000000;

}
.capitalize{
    text-transform: capitalize;
}
.latest-launches-text {
    margin-top: 47px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 31px;
    letter-spacing: 0.09em;
    color: #000000;
    max-width: 648px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
}

.view-all-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 44px;
    max-width: 224px;
    width: 100%;
    height: 43px;
    background: #3171B1;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #ffffff;
    margin-left: 15%;
    margin-top: 35px;
    cursor: pointer;
}

.image-container {
    width: 260px;
    height: 427px;
    border: 1px solid #000000;
    display: flex;
    align-items: center;
    align-items: center;
    justify-content: flex-end;
    padding-right: 15px;

}

.latest-launches-image {
    width: 350px;
    height: 392px;

}

.latest-launches-image-container1 {
    margin-top: 60px;

}

.latest-launches-image-container2 {
    margin-top: -150px;

}

@media screen and (max-width: 1460px) {
    .image-container {
        height: 355px;
        width: 188px;
    }

    .latest-launches-image {
        width: 300px;
    }

    .latest-launches-image-container1 {
        margin-top: 20px;

    }
    .latest-launches-container {
        margin: 10px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 713px;
        background: #EFF7FF;
        border: 1px solid #000000;
        padding-left: 32px;
        padding-right: 15px;
    
    }
}

@media screen and (min-width: 300px) {
    /* .slidergallary {
        width: 70%;
        height: 600px;
      margin-top: -550px;
      margin-left: 15%;

    }  */
    .video-container {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%,-50%);
    width: 75%;
   
      }
      .slidegallary2{
        /* margin-left: 500px; */
        width: 90%;
        margin-top: 60px;
      }
  
}


@media screen and (max-width: 600px) {
    #Gallary{
        position: absolute !important;
    }
    .slidergallary{
        position:relative !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-75%,-25%);
    }
    .themagical2{
       
        display: none;
    }
} 
@media screen and (min-width: 600px) {
    .themagical3{
     
        display: none;
    }
}
/* /=@media screen and (max-width: 300px) {
    .themagical2{
        width: 50%;
    }
} */



   

@media screen and (max-width: 900px) {
    .latest-launches-container {
        height: 400px;
    }

    .image-container {
        height: 150px;
        width: 100px;
        padding-right: 10px;

    }

    .latest-launches-image {
        width: 140px;
        height: 130px;
    }

    .latest-launches-image-container1 {
        margin-top: -10px;

    }

    .latest-launches-image-container2 {
        margin-top: -150px;

    }

    .latest-launches-heading {
        font-size: 10px;

    }

    .latest-launches-line {
        width: 50px;
        margin-bottom: 3px;
        margin-left: 5px;
    }

    .latest-launches-text {
        margin-top: 28px;
        font-size: 8px;
        line-height: 13px;
        max-width: 300px;

        


    }

    .view-all-button {
        padding: 8px 20px;
        max-width: 120px;
        height: 25px;
        font-size: 6px;
        line-height: 8px;
       
        margin-top: 25px;

    }


}

@media screen and (max-width: 645px) {
    .latest-launches-container {
        height: auto;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 30px 20px;
    
    }
    
    .latest-launches-heading {
        font-size: 15px;
        line-height: 12px;

    }

    .latest-launches-line {
        width: 80px;
        margin-bottom: 6px;
        margin-left: 7px;
    }

    .latest-launches-text {
        margin-top: 35px;
        font-size: 10px;
        max-width: 550px;
       

    }

    .view-all-button {
        padding: 8px 10px;
        max-width: 150px;
        height: 33px;
        font-size: 9px;

    }

    
    .image-container {
        width: 150px;
        height: 210px;
       
    }
    
    .latest-launches-image {
        width: 180px;
        height: 200px;
        
    
    }
    
    .latest-launches-image-container1 {
        margin-top: 40px;
        margin-left: 30px;
    
    }
    
    .latest-launches-image-container2 {
        margin-top: -210px;
        margin-left: auto;
    
    }
   

}

@media screen and (max-width: 440px) {
   
    .the h1{
        Font-family:Zilla Slab;
        white-space: nowrap !important;
     font-size: 25px !important;
     padding-bottom: 10px !important;
    }
    
    .image-container {
        border: none;
        height: auto;
       
        max-width: 450px;
        width: 100%;
       
    }
    
    .latest-launches-image {
        width: 100%;
       
        height: auto;
       
        border: 4px solid black;
        border-radius: 4px;
    }
    
     
    .latest-launches-image-container1 {
        margin-top: 30px;
        margin-left: 0px;
    
    }
    
    .latest-launches-image-container2 {
        margin-top: 30px;
        margin-left: 0px;
    
    }
    .the h1{
        font-size: 30px;
        
    }
    .the-magical-layout-b{
        margin-top: 4%;
       
    }
    .thee{
        Font-family:Zilla Slab;
     font-size: 10px;
     color:#0B263B;
     display: flex;
     align-items: center;
     justify-content: center;
margin-left: 4% !important;
    }
   

}


@media all and (max-width: 800px) {
   

    .minibox{
       width: inherit;
       display: flex;
       flex-direction: column;
       align-items: center;

    }

    .mini{
        margin-left: 0% !important;
    }

    
    
 }
@media all and (max-width: 1150px) {
    .minibox{
       
        margin-left: -5% !important;
 
     }

}

 
 @media all and (max-width:600px) {
   

    .loc {
        color: rgb(214, 180, 86) !important;
        font-size: 18px!important;
        font-family: "Zilla Slab" !important;
        margin-left: 38% !important ;
        font-weight: 500 !important;
        position: absolute !important;
        top: -5px !important;

}
.mini{
    margin-left: 0 !important;
}

.con{
    margin-top: -20%;
}

.slidergallary{
    /* margin-top: 205px; */
    position: absolute;
    top:60px !important;
    width: 80%;
    left: 10%;

}

    }
    @media all and (max-width:1245px) {
   

        .loc {
         
            top: -4px !important;
    }
        }
 @media all and (max-width:443px) {
    .the-magical-layout{
     font-size: 10px;
    }
    
 }
 @media screen and (max-width: 440px) {


    .w h5{
        display: flex;
        align-items: center;
        justify-content: center;
        color:#BF9654;
        font-size: 200px;  
    }
    .w h6{
        display: flex;
        align-items: center;
        justify-content: center;
        color:black;
        font-size: 16px;  
    }
    .conectivity-margin{
        margin-top: -45%;
    }
   

 }
 @media screen and (max-width: 400px) {
    .conectivity-margin{
        margin-top: -60%;
    }
    .connmobile{
        height: 100%;
        width: 100%;
        padding-left: 10%;
        padding-right: 6%;
      }
      .the h1 {
        font-size: 30px !important;
    }
 
 }





@media (max-width:600px){
    .connmobile{
        height: 100%;
        width: 100%;
        padding-left: 10%;
        padding-right: 6%;
      }
}
        
    

      
@media screen and (max-width: 405px) {
    .connectivity-header-2{
        /* font-size: 16px !important; */
        margin-left: none !important;
    }

    .the h1 {
        font-size: 30px !important;
    }
}      
   
      
@media (max-width: 380px) {
 

    .the h1 {
        font-size: 33px !important;
        padding-bottom: 10px;
    }
}      
   
/* @import url('https://fonts.googleapis.com/css2?family=Inter&display=swap'); */

 /* .location {
    display: flex;
    flex-direction: column;
    height: 269px;
    justify-content: space-between; 
    background-image: url(../../../images/completed/backk.svg);
    height:100%;
    width:100%;
    
} */

/* .address {
    display: flex;
    align-items: flex-start;

}  */

 /* .address-text {
    max-width: 398px;
    width: 100%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: rgba(255, 255, 255, 0.8);
    margin-left: 15px;
}  */

/* .map-button {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 231px;
    width: 100%;
    height: 25px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    background: #2E69A5;
    border-radius: 3px;
    margin: 0 auto;
    cursor: pointer;
} */



.contact-mail img {
    display: "flex";
    flex-direction: row;

    
    
}


.contact-mail{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
 
    color: rgba(255, 255, 255, 0.8);
}

.contact-phn {
    display: flex;
   margin-top:6%;
    margin-left: 5px;
    
}


.contact-phn {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
 
    line-height: 80px;
    color: rgba(255, 255, 255, 0.8);

    
}


.co{
font-family: Inter;
}
@media all and (max-width: 1001px) {

    .footer-location-web{
        display: none;
    }

}





 


/* .phone{
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 50px;
    margin-left: 7px;
    color: rgba(255, 255, 255, 0.8);

}

.phone div{
    margin-left: 15px;
} */

@media screen and (max-width : 1280px) {


    .address-text {
        max-width: 280px;

        font-size: 13px;
        line-height: 15px;
    }
}


/* .map-button {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 150px;
        width: 100%;
        height: 20px;
        font-size: 10px;
        line-height: 10px;
        color: #FFFFFF;
       
    }
     */

/* .contact-mail-id{
       
    font-size: 13px;
    line-height: 15px;
  
    }
    
    .contact-number{
        max-width: 290px;
     
    }
    
    .phone{
        
        font-size: 13px;
        line-height: 15px;
       
    }
    

} */
@media screen and (max-width : 1000px) {

    .location {

        height: 250px;


    }



}
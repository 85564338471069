html {
  font-size: 62.5%;
}

.archid-navbar{
  position: sticky !important;
  top: 0;
  padding-top:7px;
  z-index: 20;
  height: 74px !important;
}

.navbar-nav a {
  /* border:none; */
  font-size: 16px;
  margin: 0;
  padding: 0;
  font-family: lato;

}

/* .nav-bar{
  margin-left: 49% !important;
} */
.mainlogo{
margin-left: 3% !important;
}

.navbar-bg {
  background-color: #ffffff;
  /* background-color: #e8fdf6; */
  height: 10px !important;
}


.navtext {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  margin: 5% 1% 2%;
  /* flex-wrap: wrap !important; */
  
  gap: 30px;
  color: black;
  width: 70%;
}

.n-link {
  cursor: pointer;
  font-size: 14px;
  border-radius: 7px;
  /* color: #ECD571 !important; */
  border:2px solid #ECD571;
  /* background-color: white !important; */
}

.modal_head {
  justify-content: space-between;
}

.modal_head h3 {
  margin-bottom: 25px;
  font-weight: 500;
  font-size: 20px;
}

.react-datetime-picker__inputGroup {
  font-size: 15px;
}

.input-group input {
  height: 35px;
  font-size: 15px;
}

.nav-link .active {
  border-bottom: 0.1rem solid #09a3c8;
  padding-bottom: 1rem;
}

a {
  text-decoration: none !important;
  color: #09a3c8;
  font-weight: 550;
}

.right-link p {
  color: rgb(0, 85, 255);
  font-size: 1.6rem;

  margin-bottom: 0;
}

.right-link i {
  color: rgb(0, 85, 255);
  font-size: 2rem;
}

.right-link button {
  width: 100%;
  height: 50px;
  border: none;
}

.contact-modal {
  background-color: rgba(28, 26, 26, 0.623);
  position: fixed;
}

.ReactModal__Overlay--after-open {
  background-color: rgba(66, 66, 66, 0.61) !important;
  z-index: 10;
}

.rightnav {
  margin-right: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  margin-left: auto;
}

.navbtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.1rem;
  border: none;
}

.navbtn .textp {
  font-size: 1.5rem;
  margin-left: 1.2rem;
  padding: 0;
  color: #09a3c8;
  font-family: lato;
}

.navbtn .iconbi {
  font-size: 2.0rem;
  color: #09a3c8;
}

.getbtn {
  
  display: inline-block;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px !important;
  padding: 2px 16px !important;
  height: 38px !important;
  min-width: 96px;
  min-height: 38px;
  border: none;
  color: #fff;
  background-color: #0A253A;
  text-decoration: none;
  text-align: center;
  /* transition: background-color .17s ease,color .17s ease; */
 
/* 
  font-family: lato;
  margin-left: 2rem !important;
  margin-right: 1rem !important;  */
  /* margin-top: 1rem !important; */
  /* font-size: 16px;
  width: 150px;
  height: 40px;
  background-color:#0A253A;
  border-radius: 50;
  text-align: center; */
/* margin: 5px 5px; */
  /* color: white; */
}

.getbtn button:hover {
  background-color: rgb(45, 104, 136);
}



.ReactModal__Overlay {
  animation: moveToLeft 500ms ease-in-out;
  animation-delay: 10ms;
}



@keyframes moveToLeft {
  0% {
    transform: translateX(1000px);

  }

  90% {
    transform: translateX(-100px);
  }

  100% {
    transform: translateX(0px);

  }
}

@media all and (max-width:1280px) {
  .navtext {
    display: flex;
    justify-content: center !important;
    /* align-items: center; */
    /* flex-wrap: wrap !important; */
    margin-bottom: 6%;
    /* margin-top: 13%; */
    /* gap: 30px; */
    width: 80% !important;
  }


  .navbar-nav a {
    font-size: 14px;
    margin: 0;
    padding: 0;
  }

  .navbtn.iconbi {
    font-size: 0.3rem;
  }

  .navbar-nav .textp {
    font-size: 14px;
  }

  .getbtn .btn {
    margin: 0;
    height: 32px;
    font-size: 11px;
    width: 96px;
  }
}
@media all and (max-width:1110px) {
  .navbar-nav a {
    font-size: 14px;
    margin: 0;
    padding: 0;
  }

  .navbtn.iconbi {
    font-size: 0.3rem;
  }

  .navbar-nav .textp {
    font-size: 10px;
  }
  .n-link{
    font-size: 18px !important;
  }

  .getbtn .btn {
    margin: 0;
    /* height: 32px; */
    font-size: 11px;
    /* width: 96px; */
  }
}
@media all and (max-width: 1080px) {

  .navtext {
    display: flex;
    justify-content:center !important;
    /* align-items: center; */
    /* flex-wrap: wrap !important; */
    margin-bottom: 6%;
    /* gap: 30px; */
    width: 86% !important;
  }

  .mainlogo{
    padding-top: 7px;
    margin-left: 4% !important;
    }
}


@media all and (max-width: 1035px) {

  .navtext {
    display: flex;
    justify-content: center !important;
    /* align-items: center; */
    /* flex-wrap: wrap !important; */
    margin-bottom: 6%;
    /* gap: 30px; */
    width: 85% !important;
  }

  .navbar-nav a {
    font-size: 1.1rem;
    margin: 0;
    padding: 0;
  }
  .mainlogo{
    padding-top: 7px;
    margin-left: 4% !important;
    }


  /* .navbtn {
    /* margin-right: 1rem; 
  } */

  .navbtn.iconbi {
    font-size: 0.3rem;
  }

  .navbar-nav .textp {
    font-size: 11px;
  }

  .getbtn .btn {
    margin: 0;
    /* height: 23px; */
    /* font-size: 10px; */
    /* width: 93px; */
  }
}


@media all and (max-width: 805px) {
  /* .navtext {
    display: block;
  } */
  .navtext {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    margin: 4% 1%;
    flex-wrap: wrap !important;
    margin-bottom: 6%;
    gap: 10px;
    color: black;
    width: 60%;
  }

}


@media all and (max-width: 768px) {
  /* .navtext {
    display: block;
  } */
  .navtext {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    margin: 4% 1%;
    flex-wrap: wrap !important;
    margin-bottom: 6%;
    gap: 10px;
    color: black;
    width: 60%;
  }

  .navbar-nav a {
    margin-bottom: 1rem;
    font-size: 12px;
  }

  .rightnav {
    display: inline-block;
  }

  .navbtn {
    /* display:flex; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
  }

  .getbtn .btn {
    margin-left: 15px;
    font-size: 11px;
    padding: 2px
  }

  .navbtn .textp {
    font-size: 12px;
    margin-right: -60px;
  }
}

@media all and (max-width: 1025px) {
.n-link {
  /* margin-left: -5%; */
  font-size: 10px !important;
}
  .navtext {
    display: flex;
    justify-content: center !important;
    /* align-items: center; */
    /* flex-wrap: wrap !important; */
    margin-bottom: 6%;
    gap: 30px;
    width: 87% !important;
  }

  .mainlogo{
    padding-top: 7px;
    margin-left: 4% !important;
    }


}


@media all and (max-width: 1020px) {
  
}


@media all and (max-width: 912px) {
  /* .navtext {
    display: block;
  } */

  .navtext {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    margin: 4% 1%;
    /* flex-wrap: wrap !important; */
    margin-bottom: 6%;
    gap: 60px;
    color: black;
    width: 90% !important;
  }

  .navbar-nav a {
    font-size: 14px;
    margin-bottom: 1rem;
  }

  .navbtn .textp {
    font-size: 12px;
    margin-right: -60px;
  }

  .rightnav {
    display: inline-block;
  }

  .bi-whatsapp {
    margin-left: -37px;
  }

  .navbtn {
    /* display:flex; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }

  .getbtn .btn {
    margin-left: 15px;
    font-size: 11px;
    padding: 2px
  }

  .navbar-toggler {
    margin-right: 17px;
    font-size: 21px;
  }
}


.react-datetime-picker {
  width: 300px;
}

@media all and(min-width:1283px) {

  .navtext {
    display: flex;
    justify-content: center ;
    /* align-items: center; */
    /* flex-wrap: wrap !important; */
    margin-bottom: 6%;
    /* gap: 30px; */
    padding: 10% 1% !important;
    width: 80% !important;
  }
  .navbar-nav a {
    font-size: 16px;
    margin-bottom: 1rem;
  }

  .navbtn.iconbi {
    font-size: 5px;
  }

  .navbar-nav .textp {
    font-size: 16px;
  }


}

@media all and (max-width: 668px) {
  #modal1 {
    width: 350px !important;
  }

  .form_flex {
    display: flex;
  }

  .modal_head h3 {
    font-size: 13px;
  }

  .fa-xmark {
    font-size: 13px !important;
  }

  .img_hide {
    display: none;
  }
}

@media all and (max-width: 430px) {
  .togglenav {
    margin-right: 5px;
  }
  .navt{
    font-size:16px !important;
  }

}


@media all and (max-width: 405px) {
  .navtext {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    flex-wrap: wrap !important;
    margin-bottom: 6%;
    gap: 11px !important;
    width: 100% !important;
}
}



@media all and (max-width: 425px) {
  .navtext {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    flex-wrap: wrap !important;
    margin-bottom: 6%;
    gap: 8px !important;
    width: 100% !important;
}
}

@media all and (max-width: 390px) {
  .navtext {
 
    width: 81% !important;
}
}


.mr-auto-navtext {
  background-color: #ffffff;
}
@media all and (max-width: 1133px) {
 
  a .contact-header  {
    font-size: 10px !important;
    width: 100% !important;
    margin-left: -2% !important;
  }

}
@media all and (max-width: 1051px) {
  .mainlogo {
    height: 65px !important;
  }
  .logoimg{
    margin-left: -5%;
  }
  a .contact-header  {
    font-size: 10px !important;
    width: 100% !important;
    margin-left: -2% !important;
  }

}
@media all and (max-width: 1025px) {
  .mainlogo {
    height: 65px !important;
  }
  .logoimg{
    height: 75px !important;
    margin-left: -5% !important;
    padding-left: 20px !important;
    width: 120px !important;
  }
  .n-link{
    /* margin-left: -5%; */
    font-size: 10px !important;
  }

}
@media all and (max-width: 985px) {

  .navtext{
    width:81% !important;
  }
  .mainlogo {
    height: 80px !important;
  }
  .logoimg{
    height: 75px !important;
    margin-left: -5% !important;
    padding-left: 20px !important;
    width: 150px !important;
  }
  .n-link{
    /* margin-left: -5%; */
    font-size: 10px !important;
  }

}


@media (max-width:635px){
  .navtext {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    flex-wrap: wrap !important;
    margin-bottom: 6%;
    gap: 30px;
    width: 82%;
    
  }
}



@media (max-width:365px){
  .n-link{
    margin-left: 0%;
  }
  .navtext{
    width: 100% !important;
  }

}
.footer-image-section{
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 400px;
    width: 100%;
    margin-bottom: 80px;
}

.footer-icons{
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 150px;
    width: 100%;
}

@media screen and (max-width : 1280px ) {
    .footer-image-section{
       
        max-width: 250px;
       
    }
    
    .footer-icons{
       
        max-width: 100px;
        
    }
    .footer-logo-image{
        width: 100px;
        
    }

  
    
}
@media screen and (max-width : 600px){
   .footer-image-section{
    display: none;
   }
    
}
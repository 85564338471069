body{
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: 
#464242 !important;
}
h1,h2,h3,h4,h5,p{
  /* color: #fff; */
}
.App{

}
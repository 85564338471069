@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
*,*::after,*::before{
  box-sizing: border-box;
}
.menu-1{
  border-right: 1px solid #CAC1C1;
  margin-right: 20px;
 
  max-width: 293px;
  width: 100%;
}
.menu-title{
  font-family: 'Lato';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
letter-spacing: 0.06em;
color: #000000;
background-color: white;
margin-top: 0px;
}
.drop-down-button {
  margin-top: 20px;
  max-width: 233px;
  width: 100%;
  background-color: white;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.06em;
  color: #4D4848;
}

.form {
  background-color: white;
  padding: 10px 15px;
  border-radius: 0px;
  max-width: 293px;
  width: 100%;
  margin-top: 5px;
  margin-left: -10px;
  
}

.banner-option{
  margin-left: 10px;
   
}

.form-radio {
  font-family: system-ui, sans-serif;
  font-size: 1.0rem;
  font-weight: 400;
  line-height: 1;
  display: flex;
  justify-content: flex-start;
  background-color: white;
  margin-top: 15px;
  width: 100%;
  
}

.menu-1 input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  font: inherit;
  width: 30px;
  height: 20px;
  border: 1px solid black;
  background-color: white;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  cursor: pointer;
}

.menu-1 input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 54%, 0 55%, 53% 80%, 97% 6%, 86% 10%, 47% 72%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  background-color: white;
}

.menu-1 input[type="radio"]:checked::before {
  transform: scale(1);

}

.menu-1 input[type="radio"]:checked {
  background-color: black;

}


@media screen and (max-width: 760px) {
    
  .menu-1{
    
    height: 80px;
    max-width: 223px;
 
  }
  .form {
  
    margin-top: 40px;
   
    
  }
  .menu-title{
    
  font-size: 10px;
  line-height: 8px;
  
  }
  .drop-down-button {
    margin-top: 20px;
    max-width: 163px;
    padding: 5px 7px;
    font-size: 12px;
    line-height: 20px;
  }

  .form-radio {  
    font-size: 0.8rem;
    
  }
  
  .menu-1 input[type="radio"] {
    
    width: 20px;
    height: 15px;
  
  }
    }

@media screen and (max-width: 760px) {
    
  .menu-title{
  font-size: 13px;
  }
  .form {
    margin-top: 10px;
  }

  }
@media screen and (max-width: 300px) {
    
  .menu-title{
    
    font-size: 2px;
    line-height: 5px;
    
    }
    .drop-down-button {
     
      padding: 5px ;
      font-size: 7px;
      line-height: 10px;
    }

  }
    